
import { useState, useEffect } from "react";
//import axios from "axios";
//import ContextData from "./store/context-data";
import { Grid, Form, Input, Image } from 'semantic-ui-react';
//import {decode as base64_decode, encode as base64_encode} from 'base-64'; 
const FormCreateProperty = (props) => {
  const [sessionOrgDetails] = useState((sessionStorage.getItem('SessionOrgDetails') ? JSON.parse(sessionStorage.getItem('SessionOrgDetails')) : ''));
  const [sessionProperty] = useState((sessionStorage.getItem('sessionProperty') ? JSON.parse(sessionStorage.getItem('sessionProperty')) : ''));
  //console.log("sessionProperrty ....",sessionProperty)
  //const [localStrProp, setLocalStrProp] = useState(sessionStorage.getItem('newProperty')?JSON.parse(sessionStorage.getItem('newProperty')):'');
  //console.log('local storage for property data updated')
  // console.log(JSON.parse(localStorage.getItem('newProperty')));
  // console.log(localStrProp);
  //ObjFormProperty: Object

  //ImageUrl: "blob:http://localhost:3000/ac2cff2a-848c-407f-bb05-569fb2e03e02"

  //PropertyName: "ola"
  //  console.log(propertySession);

  // const ctx = useContext(ContextData); 
  const [editProperty] = useState(localStorage.getItem('editProperty') ? JSON.parse(localStorage.getItem('editProperty')) : '');
  const [orgAddProperty] = useState(localStorage.getItem('orgAddProperty') ? JSON.parse(localStorage.getItem('orgAddProperty')) : '');
  const [editData] = useState(localStorage.getItem('editData') ? JSON.parse(localStorage.getItem('editData')) : '');
  const [orgName, setOrgName] = useState((sessionOrgDetails && sessionOrgDetails.OrganizationDetails.OrganizationName) || (orgAddProperty && orgAddProperty.organizationDetails.organizationName) || '')
  const [propertyName, setPropertyName] = useState((sessionProperty && sessionProperty.PropertyName) || (editData && editData.propertyDetails.propertyName) || (sessionOrgDetails && sessionOrgDetails.OrganizationDetails.OrganizationName));

  const [displayName, setDisplayName] = useState((sessionProperty && sessionProperty.DisplayName) || (editData && editData.propertyDetails.DisplayName) || (sessionOrgDetails && sessionOrgDetails.OrganizationDetails.OrganizationName));

  const [shortName, setShortName] = useState((sessionProperty && sessionProperty.ShortName) || (editData && editData.propertyDetails.shortName));
  //const [imgURL, setImgURL] = useState((editData && editData.propertyDetails.imageUrl) || sessionOrgDetails || '');
  const [propStatus, setPropStatus] = useState(editData ? editData.propertyDetails.status : 1);
  //console.log(editData);
  //const [propertyPhoto, setPropertyPhoto] = useState();
  // const [fileName,setFileName] = useState();
  //const [fileExt, setFileExt] = useState();
  //  const [imageUrlServer, setImageURLServer] = useState();
  // const [imageString, setImageString] = useState();
  const [imgButtonToggle, setImageButtonToggle] = useState(sessionOrgDetails && (sessionOrgDetails.OrganizationDetails.ImageUrl ? true : false));
  // const [imgButtonTouched, setImageButtonTouched] = useState(false);
  const [preview, setPreview] = useState((sessionOrgDetails && sessionOrgDetails.OrganizationDetails.ImageUrl) || (sessionProperty && sessionProperty.ImageUrl) || (editData && editData.propertyDetails.imageUrl) || '');
  const [pageHeader] = useState(((editData || editProperty) && 'Edit Property') || 'Create New Property');
  const [errorMessage, setErrorMessage] = useState('')
  const [ObjFormProp, setObjFormProp] = useState({
    PropertyName: '',
    ShortName: '',
    ImageUrl: '',
  })
  useEffect(() => {

    if (editProperty) {
      setOrgName(editProperty.organizationDetails.organizationName);
      setPropertyName(editProperty.propertyDetails.propertyName);
      setDisplayName(editProperty.propertyDetails.displayName);
      setShortName(editProperty.propertyDetails.shortName);
      setPreview(editProperty.propertyDetails.imageUrl);
      setPropStatus(editProperty.propertyDetails.status);
    }
    if (props.editProperty) {
      console.log('editttttttt', props.editProperty)
      setOrgName(props.editProperty.organizationDetails.organizationName);
      setPropertyName(props.editProperty.propertyDetails.propertyName);
      setDisplayName(props.editProperty.propertyDetails.displayName);
      setShortName(props.editProperty.propertyDetails.shortName);
      setPreview(props.editProperty.propertyDetails.imageUrl);
      setPropStatus(props.editProperty.propertyDetails.status);
    }

    if (sessionProperty) {
      setOrgName(sessionProperty.OrganizationName);
      setShortName(sessionProperty.ShortName);
      setPropertyName(sessionProperty.PropertyName);
      setDisplayName(sessionProperty.DisplayName);
      setPropStatus(sessionProperty.Status);
    }

  }, [editProperty, props.editProperty, sessionProperty])

  const imgRemoveHandler = () => {
    setPreview(() => undefined);
    // setImageURLServer('');
    //  setImageButtonTouched(true);
    setImageButtonToggle(false);

  }


  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  const fileHandler = async (e) => {
    setImageButtonToggle(true);
    const file = e.target.files[0];
    const name = file.name;
    const lastDot = name.lastIndexOf('.');
    const fileName = name.substring(0, lastDot);
    const ext = name.substring(lastDot + 1);
    // setFileName(fileName);
    // setFileExt(ext);
    const base64 = await convertBase64(file);
    if (!e.target.files || e.target.files.length === 0) {
      //setPropertyPhoto(undefined)
      return
    } else {
      setImageButtonToggle(true);
      //   setPropertyPhoto(file);  
      const objectUrl = URL.createObjectURL(file)
      setPreview(() => objectUrl);
    }
    //  let encoded = base64_encode(file);
    // let decoded = base64_decode(encoded);
    let formatBase64 = base64.replace("data:", "")
      .replace(/^.+,/, "");
    //  let decoded = base64_decode(formatBase64); 
    //  setImageString(formatBase64); 
    const imgDataObj = {
      fileName: fileName,
      FileExtension: ext,
      ImageString: formatBase64,
      File: preview
    }
    localStorage.setItem('propImgData', JSON.stringify(imgDataObj));
  }


  // props.formPropHandler(ObjFormProp);
  // console.log(ObjFormProp);
  useEffect(() => {
    propertyName &&
      setObjFormProp({
        OrganizationName: orgName,
        PropertyName: propertyName,
        ShortName: shortName,
        ImageUrl: preview,
        DisplayName: displayName
      })
  }, [orgName, propertyName, shortName, preview, displayName])



  useEffect(() => {
    const property = { ...ObjFormProp, Status: propStatus }
    sessionStorage.setItem('sessionProperty', JSON.stringify(property));
    //  console.log('Propert updated Object with staus');
    //   console.log(property);

    props.formPropertyHandler(ObjFormProp, propStatus);
    // console.log('Passed status ',property)
  }, [ObjFormProp, propStatus, props])


  const propertyNameHandler = (e) => {
    if (displayName === "") {
      setDisplayName(e.target.value)
    }
    //  console.log(organizationName); 
    if (e.target.value.length === 0 || !propertyName) {
      // setSaveToggle(false);
      setErrorMessage('Invalid Entry')
    } else {
      // setSaveToggle(true);
      setErrorMessage('')
    }

  }
  return (
    <>
      <Grid>
        <Grid.Row columns={1} style={{ marginTop: '100px' }}>
          <Grid.Column textAlign='center' width={16} ><h3>{pageHeader}</h3></Grid.Column>
        </Grid.Row>

        <Grid.Row columns={3}>
          <Grid.Column width={9}>  {/*First Col diviison starts */}
            <Grid.Row>

              <Form>
                <Form.Group inline>
                  <label>Organization Name</label>
                  <Form.Field
                    control={Input}
                    readOnly
                    defaultValue={orgName}
                    width={10}
                  />
                </Form.Group>

                <Form.Group inline>
                  <label style={{ marginRight: '30px' }}>Property Name *</label>
                  <Form.Field
                    required
                    control={Input}
                    defaultValue={propertyName}
                    onChange={(e) => {
                      setPropertyName(e.target.value)                      
                      setErrorMessage('')
                    }}
                    onBlur={propertyNameHandler}
                    width={10}
                  />
                </Form.Group>
                {/* <p className="err"> {errorMessage} </p> */}

                {/* added by dasprakash on 19-12-2024 */}
                <Form.Group inline>
                  <label style={{ marginRight: '38px' }}>Display Name *</label>
                  <Form.Field
                    required
                    control={Input}
                    defaultValue={displayName}
                    value={displayName}
                    onChange={(e) => {
                      setDisplayName(e.target.value)
                      setErrorMessage('')
                    }}
                    onBlur={propertyNameHandler}
                    width={10}
                  />
                </Form.Group>
                {/*  */}


                <Form.Group inline>
                  <label style={{ marginRight: '57px' }}>Short Name</label>
                  <Form.Field
                    control={Input}
                    onChange={(e) => {
                      setShortName(e.target.value);
                      // console.log(e.target.value);
                    }}
                    width={10}
                    defaultValue={shortName}
                  />
                </Form.Group>

                <Form.Group inline>
                  <label className="org-status">Status</label>

                  <input type="radio" id="active" name="prop-status" value="1" checked={propStatus === 1}
                    onChange={(e) => {
                      setPropStatus(Number(e.target.value)); //console.log(e.target.value);
                    }}
                  />
                  <label>Active</label>
                  <input type="radio" id="passive" name="prop-status" value="0"
                    checked={propStatus === 0}
                    onChange={(e) => {
                      setPropStatus(Number(e.target.value));
                      // console.log(e.target.value)
                    }}
                  />
                  <label>Passive</label>
                </Form.Group>
              </Form>
            </Grid.Row>
          </Grid.Column>

          {/*First Col diviison starts */}
          <Grid.Column width={1} >

          </Grid.Column>

          <Grid.Column width={4} >
            {(preview && <Image rounded size='small' src={preview} alt='organization pic' />) ||
              <i className="fa fa-picture-o" aria-hidden="true" style={{ fontSize: '100px' }}></i>
            }
            <Grid stackable>
              <Grid.Row columns={2}>
                <Grid.Column width={5} >
                  <input type="file" style={{ color: 'transparent', marginTop: '5px' }}
                    placeholder=''
                    label=''
                    onChange={fileHandler}
                  />
                </Grid.Column>
                <Grid.Column width={5} style={{ marginTop: '5px' }}>
                  {imgButtonToggle && <button onClick={imgRemoveHandler}>Remove </button>}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>



        </Grid.Row>

      </Grid>
    </>
  )
}

export default FormCreateProperty;