import { useEffect, useState, useContext } from "react";
import axios from "axios";
import ContextData from '../store/context-data';
import { Container, Grid, Icon, Image, Form, Table, Button, TableBody } from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";


const ViewPrope = (props) => {

  const ctx = useContext(ContextData);
  const [propData, setPropData] = useState('');
  const [selectModule, setSelectModule] = useState('');
  const month = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
  let history = useHistory();
  const goBack = () => {

    history.goBack();
    setTimeout(() => window.scrollTo(0, 0), 100)
  }

  useEffect(() => {
    console.log("Connecting...");
    axios.get(`${process.env.REACT_APP_BASE_API_URL}GetModuleList`,
      { headers: { Authorization: `Bearer ${ctx.APIToken}` } }
    )
      .then((response) => {
        console.log(response.data)
        const modulesData = response.data.response;
        setSelectModule(modulesData);
        // setLoader(true);
      })
  }, [setSelectModule, ctx.APIToken]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BASE_API_URL}Organization/GetPropertyDetails`,
      {
        params: {
          'PropertyId': props.propId
        }, headers: { Authorization: `Bearer ${ctx.APIToken}` }
      },
    )
      .then((response) => {
        console.log(response.data);
        const data = response.data.response;
        setPropData(data);
        data.organizationDetails = {};
        //  data.organizationDetails.organizationName=propData.organizationDetails.organizationName;
        // data.organizationDetails.organizationId=propData.organizationDetails.organizationId;
        localStorage.setItem('orgAddProperty', JSON.stringify(data));
        // history.push('/NewProperty');
      })
  }, [props.propId])



  return (
    <Container>
      <Grid>
        <Grid.Row columns={3} style={{ paddingTop: '10%' }}>
          <Grid.Column width={2} style={{ height: '100px' }}>
            <Image
              size='small'
              src={propData && propData.propertyDetails.imageUrl}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <div style={{ float: 'left' }}> <h3 >{propData && propData.propertyDetails.propertyName} </h3></div>
            <Icon name='pen square' style={{ float: 'left' }} /> <div style={{ color: 'green', float: 'left' }}>Active</div>
            <div style={{ clear: 'left', fontSize: '11px' }}>{propData && propData.propertyDetails.organizationId}</div>

            <div>
              <b>Address Details</b>
              {propData && `${propData.propertyDetails.address.addressLine1},
                                 ${propData.propertyDetails.address.addressLine2},
                                 ${propData.propertyDetails.address.addressLine3}, 
                                 ${propData.propertyDetails.address.areaName},
                                 ${propData.propertyDetails.address.cityName},
                                 ${propData.propertyDetails.address.stateName},
                                 ${propData.propertyDetails.address.countryName},
                                 ${propData.propertyDetails.address.zipCode}, 
                                 ${propData.propertyDetails.address.landmark}`

              }

            </div>
            <div> <b>Contact Details</b>{
              propData && `${propData.propertyDetails.contactDetails.mobileNo},
                        ${propData.propertyDetails.contactDetails.telephoneNo},
                        ${propData.propertyDetails.contactDetails.emailId},
                        ${propData.propertyDetails.contactDetails.website}`
            }</div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>

        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column width={8}>
            <Table color='blue' columns={2} celled inverted selectable >
              <Table.Header>
                <Table.Row textAlign='center'>
                  <Table.HeaderCell>Fields</Table.HeaderCell>
                  <Table.HeaderCell>Values</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Organization Name</Table.Cell>
                  <Table.Cell>{props.orgName}</Table.Cell>
                </Table.Row>
                <Table.Row><Table.Cell>PropertyName</Table.Cell><Table.Cell>{(propData && propData.propertyDetails.propertyName) || '-'}</Table.Cell></Table.Row>

                {/* added by dasprakash on 19-12-2024 */}
                <Table.Row>
                  <Table.Cell>DisplayName</Table.Cell>
                  <Table.Cell>{(propData && propData.propertyDetails.displayName) || '-'}</Table.Cell>
                </Table.Row>
                {/*  */}

                <Table.Row><Table.Cell>ShortName</Table.Cell><Table.Cell> {(propData && propData.propertyDetails.shortName) || '-'}</Table.Cell></Table.Row>
                <Table.Row style={{ backgroundColor: 'darkblue' }}><Table.Cell colSpan={2}>Address</Table.Cell></Table.Row>
                <Table.Row><Table.Cell>AddressLine1</Table.Cell><Table.Cell>{(propData && propData.propertyDetails.address.addressLine1) && propData.propertyDetails.address.addressLine1 || '-'}</Table.Cell></Table.Row>
                <Table.Row><Table.Cell>AddressLine2</Table.Cell><Table.Cell>{(propData && propData.propertyDetails.address.addressLine2) && propData.propertyDetails.address.addressLine2 || '-'}</Table.Cell></Table.Row>
                <Table.Row><Table.Cell>AddressLine3</Table.Cell><Table.Cell>{(propData && propData.propertyDetails.address.addressLine3) && propData.propertyDetails.address.addressLine3 || '-'}</Table.Cell></Table.Row>
                <Table.Row><Table.Cell>Area Name</Table.Cell><Table.Cell>{(propData && propData.propertyDetails.address.areaName) && propData.propertyDetails.address.areaName || '-'}</Table.Cell></Table.Row>
                <Table.Row><Table.Cell>City Name</Table.Cell><Table.Cell>{(propData && propData.propertyDetails.address.cityName) && propData.propertyDetails.address.cityName || '-'}</Table.Cell></Table.Row>
                <Table.Row><Table.Cell>ZipCode</Table.Cell><Table.Cell>{(propData && propData.propertyDetails.address.zipCode) && propData.propertyDetails.address.zipCode || '-'}</Table.Cell></Table.Row>
                <Table.Row><Table.Cell>Landmark</Table.Cell><Table.Cell>{(propData && propData.propertyDetails.address.landmark) && propData.propertyDetails.address.landmark || '-'}</Table.Cell></Table.Row>
                <Table.Row><Table.Cell>Latitude</Table.Cell><Table.Cell>{(propData && propData.propertyDetails.address.latitude) && propData.propertyDetails.address.latitude || '-'}</Table.Cell></Table.Row>
                <Table.Row><Table.Cell>Longitude</Table.Cell><Table.Cell>{(propData && propData.propertyDetails.address.longitude) && propData.propertyDetails.address.longitude || '-'}</Table.Cell></Table.Row>
                <Table.Row style={{ backgroundColor: 'darkblue' }}><Table.Cell colSpan={2}>ContactDetails</Table.Cell ></Table.Row>
                <Table.Row><Table.Cell>Mobile No</Table.Cell><Table.Cell>{(propData && propData.propertyDetails.contactDetails.mobileCountryCode) && propData.propertyDetails.contactDetails.mobileCountryCode || '-'} {propData && propData.propertyDetails.contactDetails.mobileNo}</Table.Cell></Table.Row>
                <Table.Row><Table.Cell>TelephoneNo</Table.Cell><Table.Cell>{(propData && propData.propertyDetails.contactDetails.telephoneCountryCode) && propData.propertyDetails.contactDetails.telephoneCountryCode || '-'} {propData && propData.propertyDetails.contactDetails.telephoneAreaCode}  {propData && propData.propertyDetails.contactDetails.TelephoneNo}</Table.Cell></Table.Row>
                <Table.Row><Table.Cell>EmailId</Table.Cell><Table.Cell>{(propData && propData.propertyDetails.contactDetails.emailId) && propData.propertyDetails.contactDetails.emailId || '-'}</Table.Cell></Table.Row>
                <Table.Row><Table.Cell>Website</Table.Cell><Table.Cell>{(propData && propData.propertyDetails.contactDetails.website) && propData.propertyDetails.contactDetails.website || '-'}</Table.Cell></Table.Row>
                <Table.Row ><Table.Cell>Status</Table.Cell><Table.Cell>{propData && (propData.propertyDetails.status === 1 ? 'Active' : 'Passive')}</Table.Cell></Table.Row>
                <Table.Row style={{ backgroundColor: 'darkblue' }}><Table.Cell colSpan={2}>RegistrationDetails</Table.Cell></Table.Row>
                {
                  (propData && propData.propertyDetails.registrationDetails) && propData.propertyDetails.registrationDetails.map((item, idr) => {
                    return (
                      <Table.Row key={idr}>
                        <Table.Cell>{(item.RegistrationTypeId === 1 && 'GSTN') || 'PAN'}</Table.Cell>
                        <Table.Cell>{((item.registrationNumber && item.registrationNumber) || '-')}</Table.Cell>
                      </Table.Row>
                    )
                  })}
                <Table.Row style={{ backgroundColor: 'darkblue' }}><Table.Cell colSpan={2}>YearEndSettings</Table.Cell></Table.Row>
                <Table.Row><Table.Cell>OpeningFinancialMonth</Table.Cell><Table.Cell>{(propData && month[propData.propertyDetails.yearEndSettings.openingFinancialMonth - 1]) || '-'}</Table.Cell></Table.Row>
                <Table.Row><Table.Cell>Start Day Of The Month</Table.Cell><Table.Cell>{(propData && propData.propertyDetails.yearEndSettings.startDayOfTheMonth) || '-'}</Table.Cell></Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <div style={{ backgroundColor: '#2980B9', marginRight: '0px', borderRadius: '5px', color: 'white', paddingBottom: '12px' }}>
              <div style={{ backgroundColor: '#00337E', height: '50px', padding: '12px' }}><h4>Selected Modules</h4></div>
              {
                propData && propData.propertyDetails.selectedModules.map((module, idmnm) => {
                  return (
                    <ul key={idmnm}>
                      <li key={idmnm + module.ModuleName}>{
                        selectModule && selectModule.modules.map(
                          (item, id) => {
                            if (item.moduleCode === module.moduleCode)
                              return <span key={id}> {item.moduleName} </span>
                          }
                        )
                      }</li>
                      {
                        module.subModules && module.subModules.map((subm, kef) => {
                          return (
                            <ul key={kef}>
                              {selectModule && selectModule.modules.map(
                                (itemmodule) => {
                                  return (
                                    itemmodule.subModules.map((itemsub, idx) => {
                                      if (subm.moduleCode === itemsub.moduleCode) {
                                        return (
                                          <li key={idx}>
                                            {itemsub.moduleName}
                                          </li>)
                                      }
                                    })
                                  )
                                }
                              )}
                            </ul>
                          )
                        })
                      }
                      {
                        module.features && module.features.map((feature, kef) => {

                          return (
                            <ul key={kef}>
                              {selectModule && selectModule.modules.map(
                                (itemmodule) => {
                                  return (
                                    itemmodule.features.map((fet, idx) => {
                                      if (feature === fet.featureId) {
                                        return (
                                          <li key={idx}>
                                            {fet.featureName}
                                          </li>)
                                      }
                                    })
                                  )
                                }
                              )}
                            </ul>
                          )
                        })
                      }
                    </ul>
                  )
                })}
            </div>

          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ marginBottom: '40px' }}>
          <Grid.Column width={4}>
            <Button
              color='blue'
              onClick={goBack}
            > Go Back</Button>
          </Grid.Column>
          <Grid.Column width={4} floated='right'>

          </Grid.Column>

        </Grid.Row>
      </Grid>

    </Container>
  )
}

export default ViewPrope;
