
import { Container, Button, Grid, Table, Image, Loader, Dimmer } from 'semantic-ui-react';
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useState, useEffect, useContext } from "react";
import Result from './Result';
import ContextData from "../store/context-data";
const AddProperty = (props) => {
  const ctx = useContext(ContextData);
  const [orgAddProperty] = useState(localStorage.getItem('orgAddProperty') ? JSON.parse(localStorage.getItem('orgAddProperty')) : '');
  const [newSelectedModules] = useState(sessionStorage.getItem('newModulesforedit') ? JSON.parse(sessionStorage.getItem('newModulesforedit')) : []);
  const [newModulesfeatures] = useState(sessionStorage.getItem('newModulesFeatures') ? JSON.parse(sessionStorage.getItem('newModulesFeatures')) : []);
  const [newSubModules] = useState(sessionStorage.getItem('newSubModules') ? JSON.parse(sessionStorage.getItem('newSubModules')) : []);
  const [alert, setAlert] = useState('');
  //  const [newModules] = useState([JSON.parse(sessionStorage.getItem("newModules"))]);
  // console.log('new Modules ',newModules);
  // const[orgDetails,setOrgDetails] = useState(JSON.parse(sessionStorage.getItem("SessionOrgDetails")));
  const [newProperty] = useState(JSON.parse(sessionStorage.getItem("newProperty")));
  //const[selectedModules,setModules] = useState(JSON.parse(localStorage.getItem("selectedModules")));
  const [defaultUsers] = useState(JSON.parse(localStorage.getItem("defaultUsers")));
  //  const [orgImage,setOrgImage] = useState(ctx.orgDetails.OrganizationDetails && ctx.orgDetails.OrganizationDetails.imageData);
  //const [preview,setPreview] = useState();
  const [responseData, setResponseData] = useState();
  //const [list,setList] = useState();
  //const [OpeningFinancialMonth, setOpeningFinancialMonth] = useState(); 
  const [loaderToggle, setLoaderToggle] = useState(false);
  //const [orgImgData] = useState(localStorage.getItem("orgImgData")?JSON.parse(localStorage.getItem("orgImgData")):'');
  const [propImgData] = useState(localStorage.getItem("propImgData") ? JSON.parse(localStorage.getItem("propImgData")) : '');
  // const [imgURLServer, setImageURLServer] = useState('');
  const [propertyImgURLServer, setPropertyImgURLServer] = useState('');
  //console.log('Default users ---------',defaultUsers);
  let history = useHistory();

  const goBack = () => {
    history.goBack();
  }
  const month = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
  // const monthHandler =(e) =>{
  //
  //   //setULList((prev)=>[...prev,e.target.value]);       
  //   switch (e.target.value) {
  //            case '1':
  //                 return 'January'
  //                else{
  //                     setULListCRM('-');}    
  //             break;
  //   }


  const moduleCodeArr = newSelectedModules.map((item) => {
    if (item.ModuleCode === 'POS')
      return (
        {
          ModuleCode: item.ModuleCode,
          Features: newModulesfeatures ? newModulesfeatures.map((ft) => ft.featureId).filter(fti => fti) : [],
          //item.Features ? item.Features.map((item)=>item.featureId) :[],
          SubModules: newSubModules && newSubModules.map(item => {
            return (
              { ModuleCode: item.ModuleCode }
            )
          }).filter(item => item)

        });
    else
      return (
        {
          ModuleCode: item.ModuleCode,

        }

      )

  })
  // console.log('ModuleCode ARr',moduleCodeArr);



  useEffect(() => {
    //console.log(propImgData); 
    // free memory when ever this component is unmounted
    //   return () => URL.revokeObjectURL(objectUrl)
    propImgData &&
      axios.post(`${process.env.REACT_APP_LUCID_API_URL}UploadImage`, {
        "FileName": propImgData.fileName,
        "FileExtension": propImgData.FileExtension,
        "FileData": null,
        "ImageString": propImgData.ImageString
      }, {
        headers: { Authorization: `Bearer ${ctx.APIToken}` },
        "Content-Type": "application/json"
      }
      ).then((response) => {
        // console.log('uploaded the image');
        //console.log(response.data)
        const imageUrlServer = response.data;
        setPropertyImgURLServer(imageUrlServer.response.imageUrl);
      }).catch((error) => {
        console.log(error);
      })
  }, [propImgData, ctx.APIToken])


  // const uploadData = [  {
  //    
  // "OrganizationId": orgAddProperty.organizationDetails.organizationId,  
  // "PropertyDetails":{
  //
  //  "PropertyName": newProperty.PropertyDetails.ObjFormProperty.PropertyName,
  //  "ShortName":newProperty.PropertyDetails.ObjFormProperty.ShortName,
  //  "ImageUrl":propertyImgURLServer && propertyImgURLServer,
  //  "Address":{    
  //      "AddressLine1":newProperty.PropertyDetails.formPropAdrsData.Address.AddressLine1, 
  //      "AddressLine2":newProperty.PropertyDetails.formPropAdrsData.Address.AddressLine2,
  //      "AddressLine3":newProperty.PropertyDetails.formPropAdrsData.Address.AddressLine3,
  //      "AreaCode":Number(newProperty.PropertyDetails.formPropAdrsData.Address.AreaCode),  
  //      "AreaName":newProperty.PropertyDetails.formPropAdrsData.Address.AreaName, 
  //      "CityCode": Number(newProperty.PropertyDetails.formPropAdrsData.Address.CityCode),
  //      "CityName":newProperty.PropertyDetails.formPropAdrsData.Address.CityName,
  //      "StateCode":Number(newProperty.PropertyDetails.formPropAdrsData.Address.StateCode),
  //      "CountryCode":Number(newProperty.PropertyDetails.formPropAdrsData.Address.CountryCode),
  //      "ZipCode":newProperty.PropertyDetails.formPropAdrsData.Address.ZipCode,
  //      "Landmark":newProperty.PropertyDetails.formPropAdrsData.Address.Landmark,
  //      "Latitude":Number(newProperty.PropertyDetails.formPropAdrsData.Address.Latitude),
  //      "Longitude": Number(newProperty.PropertyDetails.formPropAdrsData.Address.Longitude),
  //   },
  //    "ContactDetails":{
  //      "MobileCountryCode":newProperty.PropertyDetails.formPropAdrsData.ContactDetails.MobileCountryCode,
  //      "MobileNo":newProperty.PropertyDetails.formPropAdrsData.ContactDetails.MobileNo,
  //      "TelephoneCountryCode":newProperty.PropertyDetails.formPropAdrsData.ContactDetails.TelephoneCountryCode,
  //      "TelephoneAreaCode":newProperty.PropertyDetails.formPropAdrsData.ContactDetails.TelephoneAreaCode,
  //      "TelephoneNo":newProperty.PropertyDetails.formPropAdrsData.ContactDetails.TelephoneNo,
  //      "EmailId":newProperty.PropertyDetails.formPropAdrsData.ContactDetails.EmailId,
  //      "Website":newProperty.PropertyDetails.formPropAdrsData.ContactDetails.Website
  //  },
  //  "Status": Number(newProperty.PropertyDetails.Status),
  //  "RegistrationDetails":newProperty.PropertyDetails.formPropRegData,
  //  "YearEndSettings":{
  //      "OpeningFinancialMonth":Number(newProperty.PropertyDetails.YearEndSettings.OpeningFinancialMonth),   
  //      "StartDayOfTheMonth": Number(newProperty.PropertyDetails.YearEndSettings.StartDayOfTheMonth),
  //  },
  //  "SelectedModules": moduleCodeArr,
  // },
  // "DefaultUsers":defaultUsers,
  //   }]; 
  //    console.log(uploadData)  

  const create = () => {
    window.scrollTo(0, 0);
    setLoaderToggle(true);


    //   const uploadData = [ {
    //       "OrganizationId": orgAddProperty.organizationDetails.organizationId,  
    //     "PropertyDetails":{
    //         "PropertyName": newProperty.PropertyDetails.ObjFormProperty.PropertyName,
    //         "ShortName":newProperty.PropertyDetails.ObjFormProperty.ShortName,
    //         "ImageUrl":propertyImgURLServer && propertyImgURLServer,
    //         "Address":{    
    //             "AddressLine1":newProperty.PropertyDetails.formPropAdrsData.Address.AddressLine1, 
    //             "AddressLine2":newProperty.PropertyDetails.formPropAdrsData.Address.AddressLine2,
    //             "AddressLine3":newProperty.PropertyDetails.formPropAdrsData.Address.AddressLine3,
    //             "AreaCode":Number(newProperty.PropertyDetails.formPropAdrsData.Address.AreaCode),  
    //             "AreaName":newProperty.PropertyDetails.formPropAdrsData.Address.AreaName, 
    //             "CityCode": Number(newProperty.PropertyDetails.formPropAdrsData.Address.CityCode),
    //             "CityName":newProperty.PropertyDetails.formPropAdrsData.Address.CityName,
    //             "StateCode":Number(newProperty.PropertyDetails.formPropAdrsData.Address.StateCode),
    //             "CountryCode":Number(newProperty.PropertyDetails.formPropAdrsData.Address.CountryCode),
    //             "ZipCode":newProperty.PropertyDetails.formPropAdrsData.Address.ZipCode,
    //             "Landmark":newProperty.PropertyDetails.formPropAdrsData.Address.Landmark,
    //             "Latitude":Number(newProperty.PropertyDetails.formPropAdrsData.Address.Latitude),
    //             "Longitude": Number(newProperty.PropertyDetails.formPropAdrsData.Address.Longitude),
    //         },
    //         "ContactDetails":{
    //             "MobileCountryCode":newProperty.PropertyDetails.formPropAdrsData.ContactDetails.MobileCountryCode,
    //             "MobileNo":newProperty.PropertyDetails.formPropAdrsData.ContactDetails.MobileNo,
    //             "TelephoneCountryCode":newProperty.PropertyDetails.formPropAdrsData.ContactDetails.TelephoneCountryCode,
    //             "TelephoneAreaCode":newProperty.PropertyDetails.formPropAdrsData.ContactDetails.TelephoneAreaCode,
    //             "TelephoneNo":newProperty.PropertyDetails.formPropAdrsData.ContactDetails.TelephoneNo,
    //             "EmailId":newProperty.PropertyDetails.formPropAdrsData.ContactDetails.EmailId,
    //             "Website":newProperty.PropertyDetails.formPropAdrsData.ContactDetails.Website
    //         },
    //         "Status": Number(newProperty.PropertyDetails.Status),
    //         "RegistrationDetails":newProperty.PropertyDetails.formPropRegData,
    //         "YearEndSettings":{
    //             "OpeningFinancialMonth":Number(newProperty.PropertyDetails.YearEndSettings.OpeningFinancialMonth),   
    //             "StartDayOfTheMonth": Number(newProperty.PropertyDetails.YearEndSettings.StartDayOfTheMonth),
    //         },
    //         "SelectedModules": moduleCodeArr,
    //     },
    //     "DefaultUsers":defaultUsers,
    // }]; 
    // console.log('Data upload inti Img Url');
    // console.log(ctx.APIToken);
    // console.log(' uploading data ---',uploadData); 
    //   console.log('area code ', newProperty.PropertyDetails.formPropAdrsData.Address.AreaCode);
    axios.post(`${process.env.REACT_APP_BASE_API_URL}CreateProperty`,
      {

        "OrganizationId": orgAddProperty.organizationDetails.organizationId,
        "PropertyDetails": {
          "PropertyId": "",
          "PropertyName": newProperty.PropertyDetails.ObjFormProperty.PropertyName,
          // added by dasprakash on 26-12-2024
          "DisplayName": newProperty.PropertyDetails.ObjFormProperty.DisplayName,
          // 
          "ShortName": newProperty.PropertyDetails.ObjFormProperty.ShortName,

          "Address": {
            "AddressLine1": newProperty.PropertyDetails.formPropAdrsData.Address.AddressLine1,
            "AddressLine2": newProperty.PropertyDetails.formPropAdrsData.Address.AddressLine2,
            "AddressLine3": newProperty.PropertyDetails.formPropAdrsData.Address.AddressLine3,
            "AreaCode": Number(newProperty.PropertyDetails.formPropAdrsData.Address.AreaCode),
            "AreaName": newProperty.PropertyDetails.formPropAdrsData.Address.AreaName,
            "CityCode": Number(newProperty.PropertyDetails.formPropAdrsData.Address.CityCode),
            "CityName": newProperty.PropertyDetails.formPropAdrsData.Address.CityName,
            "StateCode": Number(newProperty.PropertyDetails.formPropAdrsData.Address.StateCode),
            "CountryCode": Number(newProperty.PropertyDetails.formPropAdrsData.Address.CountryCode),
            "ZipCode": newProperty.PropertyDetails.formPropAdrsData.Address.ZipCode,
            "Landmark": newProperty.PropertyDetails.formPropAdrsData.Address.Landmark,
            "Latitude": Number(newProperty.PropertyDetails.formPropAdrsData.Address.Latitude),
            "Longitude": Number(newProperty.PropertyDetails.formPropAdrsData.Address.Longitude),
          },
          "ContactDetails": {
            "MobileCountryCode": newProperty.PropertyDetails.formPropAdrsData.ContactDetails.MobileCountryCode,
            "MobileNo": newProperty.PropertyDetails.formPropAdrsData.ContactDetails.MobileNo,
            "TelephoneCountryCode": newProperty.PropertyDetails.formPropAdrsData.ContactDetails.TelephoneCountryCode,
            "TelephoneAreaCode": newProperty.PropertyDetails.formPropAdrsData.ContactDetails.TelephoneAreaCode,
            "TelephoneNo": newProperty.PropertyDetails.formPropAdrsData.ContactDetails.TelephoneNo,
            "EmailId": newProperty.PropertyDetails.formPropAdrsData.ContactDetails.EmailId,
            "Website": newProperty.PropertyDetails.formPropAdrsData.ContactDetails.Website
          },
          "ImageUrl": (propertyImgURLServer && propertyImgURLServer) || '',
          "RegistrationDetails": newProperty.PropertyDetails.formPropRegData,
          "SelectedModules": moduleCodeArr,
          "Status": Number(newProperty.PropertyDetails.Status),
          "YearEndSettings": {
            "OpeningFinancialMonth": Number(newProperty.PropertyDetails.YearEndSettings.OpeningFinancialMonth),
            "StartDayOfTheMonth": Number(newProperty.PropertyDetails.YearEndSettings.StartDayOfTheMonth),
          },

        },
        "DefaultUsers": defaultUsers,
        "SystemDetails": {
          "ApplicationName": "LUCID License Management",
          "ApplicationVersion": "1.0.0.0",
          "BrowserName": "Chrome",
          "BrowserVersion": "83",
          "DeviceId": "",
          "DeviceType": "Desktop",
          "IP": "2409:4072:198:c9b1:95a:80d4:a728:36e",
          "Mac": "0210D6A869E8",
          "OPS": "",
          "Source": "React App",
          "SystemName": "EC2AMAZ-EVPTFRH",
          "SystemTimeZoneId": 1
        }
      }
      , {
        headers: { Authorization: `Bearer ${ctx.APIToken}` },
        "Content-Type": "application/json"
      }

    ).then((response) => {
      // console.log(response.data);
      const resData = response.data;
      if (resData.errorCode === 1) {
        setAlert(resData.message)
        //  setResponseData(resData.message);
      } else {
        // console.log('uploaded the data');
        // console.log(response.data);
        setResponseData(response.data);
        //  <Redirect to='/Response' /> 

        localStorage.removeItem("propImgData");
      }
      setLoaderToggle(false);
    }).catch((error) => {
      console.log(error);
      setResponseData(error.data);
      setLoaderToggle(false);
    })
    //create ends here
  }

  if (newProperty && !responseData) {
    return (
      <>
        <Container>
          <Grid>     <Grid.Row columns={1} style={{ marginTop: '100px' }}>

            <Grid.Column width={16} textAlign='center'>

              <h2> License Preview</h2>
              <p style={{ color: 'red', fontWeight: 'bold' }}>{alert}</p>
            </Grid.Column>

          </Grid.Row>

            <h3> Organisation Details</h3>
            <Grid.Row columns={2}>
              <Grid.Column width={8} >
                {loaderToggle &&
                  <Dimmer active >
                    <Loader>Loading </Loader>
                  </Dimmer>
                }
                <Table color='blue' columns={2} celled inverted selectable >
                  <Table.Header>
                    <Table.Row textAlign='center'>
                      <Table.HeaderCell> Fields</Table.HeaderCell>
                      <Table.HeaderCell> Values</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Organization Name </Table.Cell>
                      <Table.Cell>{orgAddProperty.organizationDetails.organizationName}</Table.Cell>
                    </Table.Row>

                  </Table.Body>
                </Table>
              </Grid.Column>

            </Grid.Row>

            <h3> Property Details</h3>
            <Grid.Row columns={2}>

              <Grid.Column width={8}>

                <Table color='blue' columns={2} celled inverted selectable >
                  <Table.Header>
                    <Table.Row textAlign='center'>
                      <Table.HeaderCell> Fields</Table.HeaderCell>
                      <Table.HeaderCell> Values</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>


                    <Table.Row><Table.Cell>  PropertyName</Table.Cell><Table.Cell> {newProperty.PropertyDetails.ObjFormProperty.PropertyName}  </Table.Cell>  </Table.Row>
                    {/* // added by dasprakash on 26-12-2024 */}
                    <Table.Row>
                      <Table.Cell>DisplayName</Table.Cell>
                      <Table.Cell> {newProperty.PropertyDetails.ObjFormProperty.DisplayName}  </Table.Cell>
                    </Table.Row>
                    {/*  */}
                    <Table.Row><Table.Cell>  ShortName</Table.Cell><Table.Cell>  {newProperty.PropertyDetails.ObjFormProperty.ShortName}  </Table.Cell>  </Table.Row>

                    <Table.Row style={{ backgroundColor: 'darkblue' }}> <Table.Cell>  <h4>Address</h4></Table.Cell>  <Table.Cell> </Table.Cell> </Table.Row>

                    <Table.Row><Table.Cell>  AddressLine1</Table.Cell><Table.Cell>  {newProperty.PropertyDetails.formPropAdrsData.Address.AddressLine1} </Table.Cell>  </Table.Row>
                    <Table.Row><Table.Cell>  AddressLine2</Table.Cell><Table.Cell>  {newProperty.PropertyDetails.formPropAdrsData.Address.AddressLine2}  </Table.Cell>  </Table.Row>
                    <Table.Row><Table.Cell>  AddressLine3</Table.Cell><Table.Cell>  {newProperty.PropertyDetails.formPropAdrsData.Address.AddressLine3}  </Table.Cell>  </Table.Row>
                    <Table.Row><Table.Cell>  Area Name</Table.Cell><Table.Cell> {newProperty.PropertyDetails.formPropAdrsData.Address.AreaName}  </Table.Cell>  </Table.Row>
                    <Table.Row><Table.Cell>   City Name</Table.Cell><Table.Cell> {newProperty.PropertyDetails.formPropAdrsData.Address.CityName}  </Table.Cell>  </Table.Row>
                    <Table.Row><Table.Cell>  ZipCode</Table.Cell><Table.Cell>  {newProperty.PropertyDetails.formPropAdrsData.Address.ZipCode}  </Table.Cell>  </Table.Row>
                    <Table.Row><Table.Cell>   Landmark</Table.Cell><Table.Cell> {newProperty.PropertyDetails.formPropAdrsData.Address.Landmark}  </Table.Cell>  </Table.Row>
                    <Table.Row><Table.Cell>  Latitude</Table.Cell><Table.Cell> {newProperty.PropertyDetails.formPropAdrsData.Address.Latitude}  </Table.Cell>  </Table.Row>
                    <Table.Row><Table.Cell>   Longitude</Table.Cell><Table.Cell> {newProperty.PropertyDetails.formPropAdrsData.Address.Longitude}  </Table.Cell>  </Table.Row>
                    <Table.Row style={{ backgroundColor: 'darkblue' }}> <Table.Cell>  <h4>ContactDetails</h4></Table.Cell>  <Table.Cell> </Table.Cell> </Table.Row>

                    <Table.Row><Table.Cell>   Mobile No</Table.Cell><Table.Cell> {newProperty.PropertyDetails.formPropAdrsData.ContactDetails.MobileCountryCode}  {newProperty.PropertyDetails.formPropAdrsData.ContactDetails.MobileNo} </Table.Cell>  </Table.Row>

                    <Table.Row><Table.Cell>   TelephoneNo</Table.Cell><Table.Cell> {newProperty.PropertyDetails.formPropAdrsData.ContactDetails.TelephoneCountryCode} {newProperty.PropertyDetails.formPropAdrsData.ContactDetails.TelephoneAreaCode}  {newProperty.PropertyDetails.formPropAdrsData.ContactDetails.TelephoneNo}  </Table.Cell>  </Table.Row>
                    <Table.Row><Table.Cell>   EmailId</Table.Cell><Table.Cell> {newProperty.PropertyDetails.formPropAdrsData.ContactDetails.EmailId}  </Table.Cell>  </Table.Row>
                    <Table.Row><Table.Cell>   Website</Table.Cell><Table.Cell> {newProperty.PropertyDetails.formPropAdrsData.ContactDetails.Website}  </Table.Cell>  </Table.Row>
                    <Table.Row ><Table.Cell>   Status</Table.Cell><Table.Cell> {newProperty.PropertyDetails.Status === 1 ? 'Active' : 'Passive'}  </Table.Cell>  </Table.Row>
                    <Table.Row style={{ backgroundColor: 'darkblue' }}> <Table.Cell>  <h4>RegistrationDetails</h4></Table.Cell>  <Table.Cell> </Table.Cell> </Table.Row>
                    {newProperty.PropertyDetails.formPropRegData.map((item, idt) => {

                      return (
                        <Table.Row key={idt}>
                          <Table.Cell>{item.registrationTypeName}</Table.Cell>
                          <Table.Cell>{item.RegistrationNumber}</Table.Cell>
                        </Table.Row>
                      )
                    })}
                    <Table.Row style={{ backgroundColor: 'darkblue' }}> <Table.Cell>  <h4>YearEndSettings</h4></Table.Cell>  <Table.Cell> </Table.Cell> </Table.Row>

                    <Table.Row><Table.Cell>   OpeningFinancialMonth</Table.Cell><Table.Cell> {month[newProperty.PropertyDetails.YearEndSettings.OpeningFinancialMonth - 1]}  </Table.Cell>  </Table.Row>
                    <Table.Row><Table.Cell>   Start Day Of The Month</Table.Cell><Table.Cell> {newProperty.PropertyDetails.YearEndSettings.StartDayOfTheMonth}  </Table.Cell>  </Table.Row>

                    <Table.Row style={{ backgroundColor: 'darkblue' }}> <Table.Cell colSpan={2}>  <h4>Selected Modules</h4>
                    </Table.Cell> </Table.Row>
                    <Table.Row><Table.Cell colSpan={2}>
                      {
                        newSelectedModules.map((module, id) => {
                          return (
                            <ul key={id}>
                              <li>{module.ModuleName}</li>
                              {
                                newSubModules &&
                                newSubModules.map((subModule) => {
                                  if (module.ModuleCode === 'POS')
                                    return <>
                                      <li>
                                        <ul>
                                          <li>{subModule.ModuleName}</li>
                                        </ul>
                                      </li>
                                    </>
                                  else return null;
                                })
                              }
                              {
                                newModulesfeatures &&
                                module.ModuleCode === 'POS' &&
                                newModulesfeatures.map((feature) => {
                                  return <>
                                    <li>
                                      <ul>
                                        <li>{feature.featureName}</li>
                                      </ul>
                                    </li>
                                  </>

                                })
                              }
                            </ul>
                          )

                        })}
                    </Table.Cell> </Table.Row>

                    <Table.Row style={{ backgroundColor: 'darkblue' }}> <Table.Cell>  <h4>User Details</h4></Table.Cell>  <Table.Cell> </Table.Cell> </Table.Row>
                    <Table.Row><Table.Cell> <h4>DefaultUsers</h4> </Table.Cell> <Table.Cell> </Table.Cell></Table.Row>
                    <Table.Row><Table.Cell> <h4>User ID</h4> </Table.Cell> <Table.Cell> Password</Table.Cell></Table.Row>

                    {defaultUsers && defaultUsers.map((item) => {
                      return (
                        <>
                          <Table.Row key={item.UserId}><Table.Cell> {item.UserId}</Table.Cell> <Table.Cell> {item.Password}</Table.Cell></Table.Row>

                        </>
                      )
                    })}


                  </Table.Body>
                </Table>
                <Grid>
                  <Grid.Row columns={2}>
                    {loaderToggle &&
                      <Dimmer active>
                        <Loader>Loading </Loader>
                      </Dimmer>}
                    <Grid.Column width={4} floated='left'>
                      <Button
                        color='blue'
                        onClick={goBack}
                      > Go Back</Button>
                    </Grid.Column>

                    <Grid.Column width={3} floated='right'>


                      <Button color='blue' onClick={create}>Create</Button>

                    </Grid.Column>


                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column width={8}>   {
                //right colums starts here
              }

                <Grid.Row columns={1} >

                  <Grid.Column width={3} style={{ height: '150px', marginBottom: '1000px' }}>
                    {(propertyImgURLServer && <Image size='small' rounded src={propertyImgURLServer} alt='Property Image' />) ||

                      <i className="fa fa-picture-o" aria-hidden="true" style={{ fontSize: '150px' }}></i>

                    }

                  </Grid.Column>
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row >
              <Grid.Column width={4} floated='right'>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </>

    )
  } else if (responseData) {
    return (
      <Result data={responseData} />
    )
  }

  else {

    return (
      <>
        <h3> Loading.....</h3>

      </>
    )
  }

}

export default AddProperty;