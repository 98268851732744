import { useEffect, useState, useContext } from "react";
import axios from "axios";
import ContextData from '../store/context-data';
import { Container, Grid, Header, Icon, Image, Card, Form, Input, Button, Modal, Table, Radio, Dropdown } from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import copy from 'copy-to-clipboard';
import { useMediaQuery } from 'react-responsive';

const ViewOrg = (props) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  // For passing user data to production setup
  const [userData] = useState(sessionStorage.getItem("SessionUserLoginData") ? JSON.parse(sessionStorage.getItem("SessionUserLoginData")) : []);
  const ctx = useContext(ContextData);
  const [orgData, setOrgData] = useState('');
  const [modal, setModal] = useState(false);
  const [modalOrgName, setModalOrgName] = useState();
  const [modalToken, setModalToken] = useState(false);
  // const [modalOrgName,setModalOrgName] = useState(); 
  const [updateToggle, setupdateToggle] = useState(false);
  const [updateUserHeader, setUpdateUserHeader] = useState('');
  const [secondOpen, setSecondOpen] = useState(false);
  const [historyData, setHistoryData] = useState();
  const [remarksUdateMessage, setRemarksUdateMessage] = useState();
  const [remarksOrgId, setRemarksOrgId] = useState();
  const [usersData, setUsersData] = useState();
  const [remarksData, setRemarksData] = useState();
  const [enteredRemarks, setEnteredRemarks] = useState();
  const [charCount, setCharCount] = useState();
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [userRights, setUserRights] = useState(JSON.parse(localStorage.getItem('userRights')));
  //console.log("here is view org initial state //",ctx.userRights, JSON.parse(localStorage.getItem('userRights')));
  //  const [TokenType,setTokenType] = useState(1); 

  const [tokenData, setTokenData] = useState("");
  const [tokenDataError, setTokenDataError] = useState("");
  const [tokenDataCaptain, setTokenDataCaptain] = useState("");
  const [tokenDataCaptainError, setTokenDataCaptainError] = useState("");
  const [webInventory, setWebInventory] = useState("");
  const [webInventoryError, setWebInventoryError] = useState("");

  const [tokenPromis, setTokenPromis] = useState();
  const [outletList, setOutletList] = useState();
  const [propertyId, setPropertyId] = useState();
  const [propertyName, setPropertyName] = useState();
  const [twoCopyStyle, setTwoCopyStyle] = useState();
  const [captainStyle, setCaptainStyle] = useState();
  const [promisStyle, setPromisStyle] = useState();
  const [posSync, setPOSSync] = useState(false);
  //const [POSSyncStatus, setPOSSyncStatus] = useState(false);
  const [addProperty, setAddProperty] = useState();
  const [userType, setUserType] = useState();
  const [Create, setCreate] = useState();
  const [Edit, setEdit] = useState();
  const [View, setView] = useState();
  const [ViewUsers, setViewUsers] = useState();
  const [productLink, setProductLink] = useState();
  const [getToken, setGetToken] = useState();
  const [isCopied, setCopySuccess] = useState(false);


  // console.log(posSync)
  const [propertyOptions, setPropertyOptions] = useState([{
    key: '1',
    text: 'state',
    value: 'add'
  }]);

  // console.log(userRights);
  //const UrlType = 1;
  let history = useHistory();
  const goBack = () => {
    history.push('/');
    setTimeout(() => window.scrollTo(0, 0), 100)
  }

  const remarksInputHandler = (e, data) => {

    setEnteredRemarks(data.value);
    //console.log(data.value);
    setRemarksUdateMessage('');

    var currentText = data.value;
    var characterCount = currentText.length;
    setCharCount(characterCount);

  }

  const historyHandler = () => {

    setSecondOpen(true)
    axios.get(`${process.env.REACT_APP_BASE_API_URL}GetOrganizationRemarksHistory`,
      {
        params: {
          'OrganizationId': remarksOrgId,
          'NoOfRowsPerPage': 25,
          'CurrentPageNumber': 1
        },
        headers: { Authorization: `Bearer ${ctx.APIToken}` }
      },
    )
      .then((response) => {
        // console.log('getting remarks history.......,.,');
        // console.log(response.data)
        const data = response.data.response;
        setHistoryData(data);
      })
  }
  const remarksUpdateHandler = () => {
    axios.post(`${process.env.REACT_APP_BASE_API_URL}UpdateOrganizationRemarks`, {
      "OrganizationId": remarksOrgId,
      "Remarks": enteredRemarks,
    },
      {
        headers: { Authorization: `Bearer ${ctx.APIToken}` },
        "Content-Type": "application/json"
      })
      .then((response) => {
        //console.log(response.data);
        setRemarksUdateMessage('Updated Successfully')
      })
  }
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BASE_API_URL}GetOrganizationDetails`,
      {
        params: {
          'OrganizationId': props.orgId
        }, headers: { Authorization: `Bearer ${ctx.APIToken}` }
      },
    )
      .then((response) => {
        // console.log(response.data);
        const data = response.data.response;
        setOrgData(data)
        // history.push('/NewProperty');
      })
    localStorage.removeItem("selectedModules");
    sessionStorage.removeItem('SessionOrgDetails');
    sessionStorage.removeItem('newProperty');
    localStorage.removeItem('defaultUsers');
    sessionStorage.removeItem('newModulesforedit');
    sessionStorage.removeItem('newModulesFeatures');
    sessionStorage.removeItem('newSubModules');
    sessionStorage.removeItem('sessionProperty');
  }, [ctx.APIToken, props.orgId])


  const addPropertyHandler = () => {
    localStorage.removeItem('editData');
    localStorage.setItem('orgAddProperty', JSON.stringify(orgData));
    localStorage.removeItem('editProperty');
    localStorage.removeItem("selectedModules");
    sessionStorage.removeItem('SessionOrgDetails');
    sessionStorage.removeItem('newProperty');
    localStorage.removeItem('defaultUsers');
    sessionStorage.removeItem('newModulesforedit');
    sessionStorage.removeItem('newModulesFeatures');
    sessionStorage.removeItem('newSubModules');
    sessionStorage.removeItem('sessionProperty');
    history.push('/NewProperty');
  }

  //   const editHandler = () =>{ 
  //   axios.get(`${process.env.REACT_APP_BASE_API_URL}GetOrganizationDetails`,
  //   { params: {
  //    'OrganizationId': orgData && orgData.organizationDetails.organizationId
  //   },  headers: { Authorization: `Bearer ${ctx.APIToken}`}},  
  //   )
  //  .then((response)=>{
  //     //   console.log(response.data);
  //        const data = response.data.response;
  //        localStorage.setItem('editData',JSON.stringify(data));
  //      //  history.push('/NewOrganization');
  //  })         
  // } 

  // const editPropertyHandler = (propId) =>{  


  //   }
  const remarksHandler = () => {
    setModal(true);
    setRemarksData(() => '');
    setRemarksUdateMessage('');
    setUpdateUserHeader(() => '');
    axios.get(`${process.env.REACT_APP_BASE_API_URL}GetOrganizationRemarks`,
      {
        params: {
          'OrganizationId': orgData && orgData.organizationDetails.organizationId
        },
        headers: { Authorization: `Bearer ${ctx.APIToken}` }
      },
    )
      .then((response) => {
        //console.log('Remarks Data for Remarks');
        // console.log(response.data);
        const data = response.data.response;
        setRemarksData(data);
        if (data.remarksDetails.remarks === '') {
          setUpdateUserHeader('');
          setupdateToggle(false);
        }
        else {
          setupdateToggle(true);
          data.remarksDetails &&
            setUpdateUserHeader(` Laste Update |${data.remarksDetails.lastUpdatedDateTime}
        | By ${data.remarksDetails.lastUpdatedUser}`);
        }
      })
    setModalOrgName(orgData && orgData.organizationDetails.organizationName);
    setRemarksOrgId(orgData && orgData.organizationDetails.organizationId);

  }
  const usersHandler = () => {
    axios.get(`${process.env.REACT_APP_BASE_API_URL}GetOrganizationUserList`,
      {
        params: {
          'OrganizationId': orgData && orgData.organizationDetails.organizationId
        },
        headers: { Authorization: `Bearer ${ctx.APIToken}` }
      },
    )
      .then((response) => {
        const data = response.data.response;
        setUsersData(data);
      })
    setModalOrgName(orgData && orgData.organizationDetails.organizationName);
    setOpen(true);
  }
  useEffect(() => {

    setUserRights(() => ctx.userRights)
    // console.log('view org session user rights',ctx.userRights )

  }, [])

  useEffect(() => {
    userRights &&
      userRights.forEach((element) => {
        // console.log('element view org .....',element); 
        element.userType && setUserType(element.userType)
        element.Create && setCreate(element.Create)
        element.Edit && setEdit(element.Edit)
        element.View && setView(element.View)
        element.AddProperty && setAddProperty(element.AddProperty)
        element.ViewUsers && setViewUsers(element.ViewUsers)
        element.productLink && setProductLink(element.productLink)
        element.getToken && setGetToken(element.getToken)
      })
  }, [userRights])


  // GET 'https://dev.lucidits.com/LUCIDLicenseManagementAPI/V1/GetToken?PropertyId=10000000131000000002&TokenType=1'




  const tokensHandler = (propertyId, propertyName) => {
    setPropertyId(propertyId);
    setPropertyName(propertyName);
    setTokenPromis('');
    setTokenData("");
    setTokenDataError("");
    setTokenDataCaptain("");
    setTokenDataCaptainError("");
    setWebInventory("");
    setWebInventoryError("");
    // getTokenApi(propertyId)

    // console.log('property id ',propertyId);

    //'https://dev.lucidits.com/LUCIDLicenseManagementAPI/V1/GetPropertyOutletList?PropertyId=10000000131000000002'  
    // axios.get(`${process.env.REACT_APP_BASE_API_URL}GetPropertyOutletList`,
    //   {
    //     params: {
    //       'PropertyId': propertyId,
    //     },
    //     headers: { Authorization: `Bearer ${ctx.APIToken}` }
    //   },
    // )
    //   .then((response) => {
    //     const data = response.data.response;
    //     // console.log('outlet data ',data);
    //     setOutletList(data);
    //   })
    //'https://dev.lucidits.com/LUCIDLicenseManagementAPI/V1/GetPOSSyncStatus?PropertyId=%3C%3CPropertyId%3E%3E'

    axios.get(`${process.env.REACT_APP_BASE_API_URL}GetPOSSyncStatus`,
      {
        params: {
          'PropertyId': propertyId,
        },
        headers: { Authorization: `Bearer ${ctx.APIToken}` }
      },
    )
      .then((response) => {
        const data = response.data.response;
        //console.log('POS Sync ',data);
        setPOSSync(data.posSyncStatus);
      })
    setModalToken(true)
  }

  // added by dasprakash on 16-01-2025
  const getTokenApi = (type) => {
    axios.get(`${process.env.REACT_APP_BASE_API_URL}GetToken`,
      {
        params: {
          'PropertyId': propertyId,
          'TokenType': type
        },
        headers: { Authorization: `Bearer ${ctx.APIToken}` }
      },
    )
      .then((response) => {
        if (response.data.errorCode === 0) {
          const data = response.data.response;
          // console.log(data);
          switch (type) {
            case 1:
              setTokenData(data);
              break;
            case 2:
              setTokenDataCaptain(data);
              break;
            case 3:
              setWebInventory(data);
            default:
              // Handle default case if necessary
              break;
          }
        }
        else {
          let errorMsg = response.data.message
          switch (type) {
            case 1:
              setTokenDataError(errorMsg);
              break;
            case 2:
              setTokenDataCaptainError(errorMsg);
              break;
            case 3:
              setWebInventoryError(errorMsg);
            default:
              // Handle default case if necessary
              break;
          }
        }
      })
  }
  // 





  //curl --location --request POST 'https://dev.lucidits.com/LUCIDLicenseManagementAPI/V1/UpdatePOSSyncStatus' \

  const posSyncHandler = (e, data) => {
    setPOSSync(() => posSync === true ? false : true)
    axios.post(`${process.env.REACT_APP_BASE_API_URL}UpdatePOSSyncStatus`, {
      'PropertyId': propertyId,
      'POSSyncStatus': (data.checked === true ? true : false)
    },
      { headers: { Authorization: `Bearer ${ctx.APIToken}` } },
    )
      .then((response) => {
        const data = response.data.response;
      })
  }

  const promisposHandler = (e, data) => {
    //  console. log('data value is .........',data.value)
    axios.get(`${process.env.REACT_APP_BASE_API_URL}GetToken`,
      {
        params: {
          'PropertyId': propertyId,
          'OutletCode': data.value,
          'TokenType': 3
        },
        headers: { Authorization: `Bearer ${ctx.APIToken}` }
      },
    )
      .then((response) => {
        const data = response.data.response;

        setTokenPromis(data);
      })
  }


  useEffect(() => {
    outletList &&
      setPropertyOptions(outletList.outletList.map((item, id) => {
        return ({
          key: id,
          text: item.outletName,
          value: item.outletCode
        })
      }));

    //  console.log(outletList);
  }, [outletList])



  const lucidPOSHandler = (link) => {
    const orgid = orgData.organizationDetails.organizationId;
    axios.get(`${process.env.REACT_APP_LUCID_API_URL}GetLUCIDApplicationUrl`, {
      params: {
        'OrganizationId': orgid,
        'UrlType': link
      }, headers: { Authorization: `Bearer ${ctx.APIToken}` }
    }
    ).then((response) => {
      const url = response.data.response.url
      window.open(url)
    })

  }
  useEffect(() => {
    if (searchTerm) {
      sessionStorage.setItem('searchItem', searchTerm);
    }

    sessionStorage.getItem('searchItem') &&
      setSearchTerm(sessionStorage.getItem('searchItem'));
  }, [searchTerm])


  useEffect(() => {
    localStorage.removeItem('editProperty');
    localStorage.removeItem('orgAddProperty');
    localStorage.removeItem('editData');
    localStorage.removeItem("selectedModules");
    sessionStorage.removeItem('SessionOrgDetails');
    sessionStorage.removeItem('newProperty');
    localStorage.removeItem('defaultUsers');
    sessionStorage.removeItem('newModulesforedit');
    sessionStorage.removeItem('newModulesFeatures');
    sessionStorage.removeItem('newSubModules');
    sessionStorage.removeItem('sessionProperty');
  }, [])

  const lucidProductSetupHandler = () => {

    axios.get(`${process.env.REACT_APP_BASE_API_URL}GetUserManagementToken`,
      {
        params: {
          'OrganizationId': orgData.organizationDetails.organizationId
        },
        headers: { Authorization: `Bearer ${ctx.APIToken}` }
      },
    )
      .then((response) => {
        //const data = response.data.response;
        //setUsersData(data);
        if (response.data.errorCode === 1) alert(response.data.message)
        console.log(response.data)
        if (response.data.errorCode === 0) {
          console.log(orgData.organizationDetails)

          const params = { token: response.data.response.token, imageUrl: orgData.organizationDetails.imageUrl, orgName: orgData.organizationDetails.organizationName, userName: userData.response.userName, userType: userData.response.userType, serverDate: userData.response.serverDate }
          console.log("kkssd0", params)
          const searchParams = new URLSearchParams(params);
          console.log("searchParams", searchParams)
          // const encoded = encodeURI(`http://localhost:3000/?${(searchParams)}`);
          // for development mode
          const encoded = encodeURI(`https://devsetup.lucidtest.in/?${(searchParams)}`);
          // const encoded = encodeURI(`http://localhost:4545/?${(searchParams)}`);
          // const encoded = encodeURI(`http://localhost:4545/?${(searchParams)}`);
          //  const encoded = encodeURI(`https://setup.lucidpos.com/?${(searchParams)}`);
          window.open(encoded, '_blank')
        }
      })

  }

  return (
    <Container style={{ minHeight: '100px' }}>
      <Grid>
        <Grid.Row columns={3} className='row-container-vieworg' style={isTabletOrMobile ? { paddingTop: '3%', paddingBottom: '50px', height: '20%' } : { paddingTop: '55px', paddingBottom: '10px', height: '230px' }}>
          <Grid.Column width={2}>
            {orgData && (orgData.organizationDetails.imageUrl && <Image
              rounded
              size='small'
              src={orgData && orgData.organizationDetails.imageUrl}
            />) || <Icon name='picture' size='massive' style={{ marginRight: '1px' }} />
            }
          </Grid.Column>
          <Grid.Column width={8}>
            <div style={{ float: 'left', marginRight: '5px' }}> <h3 >{orgData && orgData.organizationDetails.organizationName} </h3></div>
            {
              (Edit || userType === 2) &&
              <Link to={{
                pathname: "/NewOrganization",
                state: {
                  ViewOrg: true,
                  OrganizationId: orgData && orgData.organizationDetails.organizationId
                }
              }}
                // onClick={editHandler}
                style={{ marginRight: '1%' }}
                key='Edit'
              >
                <Icon name='edit' color='red' style={{ float: 'left', marginLeft: '5px' }} />
              </Link>
            }
            <div style={{ color: 'green', float: 'left' }}>Active</div>
            <div style={{ clear: 'left', fontSize: '11px' }}>
              {orgData && orgData.organizationDetails.organizationId}
            </div>

            <div><b>Address Details</b>{
              orgData && `${orgData.organizationDetails.address.addressLine1},
                                 ${orgData.organizationDetails.address.addressLine2},
                                 ${orgData.organizationDetails.address.addressLine3}, 
                                 ${orgData.organizationDetails.address.areaName},
                                 ${orgData.organizationDetails.address.cityName},
                                 ${orgData.organizationDetails.address.stateName},
                                 ${orgData.organizationDetails.address.countryName},
                                 ${orgData.organizationDetails.address.zipCode}, 
                                 ${orgData.organizationDetails.address.landmark}`
            }

            </div>
            <div> <b>Contact Details</b>{
              orgData && `${orgData.organizationDetails.contactDetails.mobileNo},
                        ${orgData.organizationDetails.contactDetails.telephoneNo},
                        ${orgData.organizationDetails.contactDetails.emailId},
                        ${orgData.organizationDetails.contactDetails.website}`
            }</div>
          </Grid.Column>
          <Grid.Column>
          </Grid.Column>
          <Grid.Column width={16}>
            <div style={{ float: 'left', padding: '10px', paddingBottom: '0px' }}>
              <li className="viewOrg-Remarks" onClick={usersHandler}>
                {(ViewUsers === true || userType === 2) && 'Users'}
              </li>
              <li onClick={remarksHandler} className="viewOrg-Remarks">
                Remarks
              </li>

              <li
                onClick={() => lucidPOSHandler(1)}
                className="viewOrg-Remarks"
              >
                {(productLink || userType === 2) && 'LUCIDPOS'}
              </li>
              <li
                onClick={() => lucidPOSHandler(2)}
                className="viewOrg-Remarks">
                {(productLink || userType === 2) &&
                  'LUCIDOnlineOrders'}
              </li>
              <li
                onClick={lucidProductSetupHandler}
                className="viewOrg-Remarks">
                {(productLink || userType === 2) &&
                  'LucidProductSetup'}
              </li>
            </div>
          </Grid.Column>
          <Grid.Column width={12} style={{ backgroundColor: 'white', paddingTop: '10px' }}>
            <div className="addprop-mobile"  >
              <Form>
                <Form.Field inline>
                  <Input
                    value={searchTerm}
                    style={{ marginRight: '10px' }}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                      if (e.target.value === '') sessionStorage.removeItem('searchItem');
                    }}
                    icon={searchTerm && <Icon name='delete' link onClick={() => {
                      setSearchTerm('');
                      sessionStorage.removeItem('searchItem');
                    }} />}
                  />
                  <Button color='blue'>Search</Button>
                </Form.Field>

              </Form>
            </div>
            <div style={{ marginRight: '60px' }}>
              {(addProperty || userType === 2) &&
                <Button color='blue' onClick={addPropertyHandler} floated="right">
                  Add Property
                </Button>
              }
            </div>
          </Grid.Column>

        </Grid.Row>
        <Grid.Row className="vcard-section" style={{ marginTop: '80px' }}>
          <Card.Group itemsPerRow={5} className='dash-card-links'
            style={{ paddingTop: '22%' }}>
            {
              orgData &&
              orgData.propertyList.map((field) => {
                if (searchTerm === "") {
                  return field
                } else if (
                  field.propertyName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  // added by dasprakash on 26-12-2024
                  field.propertyDisplayName?.toLowerCase().includes(searchTerm.toLowerCase())
                  // 
                ) {
                  return field;
                }
              }).map((field) => {
                if (field) {
                  return (
                    <Card color='grey' key={field.propertyId} style={{ marginLeft: '20px', width: '310px' }}
                    >
                      <Card.Content>
                        {(field.imageUrl && <Image
                          floated='left'
                          style={{ width: '50px' }}
                          src={field.imageUrl}
                        />) || <Icon name='picture' color='grey' size='huge' style={{ float: 'left' }} />
                        }
                        <Card.Meta
                          style={field.status === 1 ? { color: 'green', position: 'absolute', right: '5px', top: '0' } : { color: 'red', position: 'absolute', right: '5px', top: '0' }}
                          floated='right'
                        >{field.status === 1 ? 'Active' : 'Passive'}
                        </Card.Meta>
                        <div
                        >
                          <div style={{ fontSize: '2vh' }}><b>{field.propertyName}</b></div>
                          <div style={{ fontSize: '10px', lineHeight: '5px', marginBottom: '5px' }}>{field.propertyId}</div>
                        </div>
                        <div style={{ fontSize: '2vh' }}>
                          {field.areaName}  {field.cityName}
                        </div>
                      </Card.Content>
                      <Card.Content extra>
                        <div>
                          {(Edit || userType === 2) &&
                            <Link to={{
                              pathname: "/NewProperty",
                              state: {
                                propertyId: field.propertyId,
                                orgName: orgData && orgData.organizationDetails.organizationName,
                                orgId: orgData && orgData.organizationDetails.organizationId
                              }
                            }}

                              style={{ marginRight: '5%' }}
                              key='Edit'
                            >
                              <Icon name='edit' color='red' />
                              <span>  Edit </span>
                            </Link>
                          }
                          {(View || userType === 2) &&
                            <Link to={{
                              pathname: "/ViewPrope",
                              state: {
                                propertyId: field.propertyId,
                                orgName: orgData && orgData.organizationDetails.organizationName
                              }
                            }}
                              color='green'
                              style={{ marginRight: '5%' }}
                              key='View'
                            >
                              <Icon name='eye' color='green' />
                              <span> View </span>
                            </Link>
                          }
                          {(getToken || userType === 2) &&
                            <li onClick={tokensHandler.bind(this, field.propertyId, field.propertyName)}
                              style={{
                                marginRight: '5%',
                                listStyle: 'none',
                                display: 'inline',
                                cursor: 'pointer'
                              }}
                              key='tokens'
                            >
                              <Icon name='ticket' color='blue' />
                              <span> Tokens </span>
                            </li>
                          }
                        </div>
                        <Card.Content extra style={{ fontSize: '10px', color: 'black', borderTop: 'solid 1px', borderTopColor: 'rgb(221, 221, 221)' }}>
                          <div style={{ float: 'left', marginRight: '10px', marginTop: '5px' }}> Created Date: {field.createdDate} </div>
                          <div style={{ marginTop: '5px', wordBreak: 'keep-all' }}> User ID: {field.createdBy}</div>
                        </Card.Content>
                      </Card.Content>
                    </Card>
                  )
                } else return null
              })

            }
          </Card.Group>
        </Grid.Row>
        <Grid.Row style={{ marginBottom: '50px' }}>
          <Grid.Column width={4}>
            <Button
              color='blue'
              onClick={goBack}
            > Go Back</Button>
          </Grid.Column>
          <Grid.Column width={4} floated='right'>

          </Grid.Column>

        </Grid.Row>
      </Grid>

      <Modal style={{ maxWidth: '30%' }}
        closeIcon
        dimmer='blurring'
        open={modal}
        //  trigger={modal}
        onClose={() => setModal(false)}
        onOpen={() => setModal(true)}
      >
        {
          <>
            <Header icon='archive' content={`${modalOrgName} - Remarks`} />
            <div style={{ textAlign: 'center', padding: '5px', paddingTop: '10px', fontSize: '12px', maxWidth: '400px' }}>
              {updateToggle && updateUserHeader
              }
            </div>
            <div style={{ fontSize: '12px', textAlign: 'right', paddingRight: '19px' }}>
              <li onClick={historyHandler} className="viewOrg-Remarks">
                History
              </li>

            </div>
            <Modal.Content  >
              <Form>
                <Form.TextArea
                  label=''
                  placeholder='Remarks'
                  onChange={remarksInputHandler}
                  defaultValue={remarksData && remarksData.remarksDetails.remarks}
                  style={{ height: '200px' }}
                />
              </Form>
              <div style={{ color: 'green', fontSize: '12px', textAlign: 'right' }}> {remarksUdateMessage}</div>
              <div style={{ fontSize: '12px' }}>(Max Char {charCount}/1000)</div>
            </Modal.Content>
          </>
        }
        <Modal.Actions>
          <Button color='blue' onClick={remarksUpdateHandler}>
            <Icon name='save' /> Update
          </Button>

        </Modal.Actions>

        <Modal className='modal-history'
          onClose={() => setSecondOpen(false)}
          open={secondOpen}
          size='tiny'
        >
          <Modal.Header>History</Modal.Header>
          <Modal.Content>
            <div style={{ maxHeight: '260px', overflowY: 'scroll' }}>
              {historyData && historyData.remarksHistory.map((item, idx) => {
                return <> <div key={idx}
                  style={{ fontSize: '12px' }}
                > <b>
                    {` ${item.updatedDateTime} , ${item.updatedUser}`}
                  </b>
                </div>
                  <Form.TextArea disabled value={` ${item.remarks} `} style={{ width: '100%', marginBottom: '20px' }}>
                  </Form.TextArea>
                </>
              })
              }
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              icon='check'
              content='Close'
              onClick={() => setSecondOpen(false)}
            />
          </Modal.Actions>
        </Modal>
      </Modal>



      <Modal style={{ maxWidth: '450px' }}
        closeIcon
        dimmer='blurring'
        open={open}
        //trigger={modal}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <Header icon='archive' content={`${modalOrgName} - Users`} />
        <Modal.Content >
          <Table celled>
            <Table.Header>
              <Table.Row textAlign='center'>
                <Table.HeaderCell>User Id</Table.HeaderCell>
                <Table.HeaderCell>Password</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {usersData &&
                usersData.users.map((user, idi) => {
                  return (
                    <>
                      <Table.Row key={idi}>
                        <Table.HeaderCell textAlign='center' colSpan={2}> {user.userLevel}</Table.HeaderCell>
                      </Table.Row>
                      {user.userList.map((user, idx) => {
                        return <>
                          <Table.Row key={idx}>
                            <Table.Cell>{user.userId}</Table.Cell>
                            <Table.Cell>{user.password}</Table.Cell>
                          </Table.Row>
                        </>
                      })}
                    </>
                  )
                }
                )}

            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
        </Modal.Actions>
      </Modal>



      {/* token popup */}
      <Modal style={{ maxWidth: '550px' }}
        closeIcon
        dimmer='blurring'
        open={modalToken}
        //  trigger={modal}
        onClose={() => setModalToken(false)}
        onOpen={() => setModalToken(true)}
      >
        <Header
          style={{ position: "relative" }}
          icon='archive'
          content={
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", }}>
              {`${propertyName} - Tokens`}
            </div>
          }
        />

        <Modal.Content >
          <div >
            {/* TwoWaySync Services Token  */}
            <div style={{ marginBottom: "15px" }}>
              <span style={{ fontSize: "14px", fontWeight: "bold" }}>TwoWaySync Services Token /PROMIS - POS Integration Token</span><br />
              <small style={{ fontSize: '11px', color: '#5C9CE8' }}>[Note: PROMIS-POS Token use to integrate ‘Orders’ , ‘Table Reservation’ , ’E-Bill’ , and ‘Loyalty’]</small>
              <div style={{ paddingLeft: "10px", display: "flex", alignItems: "center", justifyContent: "space-between", borderRadius: "5px", height: "36px", border: tokenDataError !== "" ? "1px solid #FF0000" : "1px solid rgba(0, 0, 0, 0.2)", marginTop: "6px" }}>
                {tokenData !== "" ?
                  <input
                    onKeyDown={(e) => {
                      // Allow navigation keys: ArrowLeft, ArrowRight, Home, End
                      if (
                        ["ArrowLeft", "ArrowRight", "Home", "End", "Tab", "Shift", "Control"].includes(
                          e.key
                        )
                      ) {
                        return;
                      }
                      e.preventDefault(); // Prevent other keys
                    }}
                    onInput={(e) => e.preventDefault()}  // Prevent other input actions
                    onPaste={(e) => e.preventDefault()}  // Prevent pasting
                    onClick={() => {
                      console.log("");
                    }}
                    style={{ backgroundColor: "transparent", outline: "none", fontSize: "12px", cursor: "default", fontWeight: "400", height: "33px", border: "none", width: "90%" }} defaultValue={tokenData.token} />
                  :
                  <span onClick={() => getTokenApi(1)} style={{ width: "100%", marginRight: "10px", textAlign: "end", fontSize: '12px', color: '#5C9CE8', cursor: "pointer" }}>View Token</span>
                }
                {tokenData &&
                  <div onClick={() => copy(tokenData.token)} className="copyBtn" style={{ width: "9%", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", borderLeft: "1px solid rgba(0, 0, 0, 0.2)", height: "36px", }}>
                    <svg width="18" height="18" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.13124 0C2.73312 0 0.789062 1.94406 0.789062 4.34217V9.709C0.789062 10.0218 1.04264 10.2754 1.35543 10.2754C1.66823 10.2754 1.9218 10.0218 1.9218 9.709V4.34217C1.9218 2.56965 3.35872 1.13274 5.13124 1.13274H10.4271C10.7399 1.13274 10.9934 0.879168 10.9934 0.566371C10.9934 0.253573 10.7399 0 10.4271 0H5.13124Z" fill="black" fillOpacity="0.8" />
                      <path fillRule="evenodd" clipRule="evenodd" d="M12.2317 2.67573C9.78424 2.40219 7.27471 2.40219 4.82727 2.67573C4.13005 2.75366 3.56977 3.30235 3.48759 4.00498C3.19732 6.4868 3.19732 8.99399 3.48759 11.4758C3.56977 12.1784 4.13005 12.7271 4.82727 12.8051C7.27471 13.0786 9.78424 13.0786 12.2317 12.8051C12.9289 12.7271 13.4892 12.1784 13.5714 11.4758C13.8616 8.99399 13.8616 6.4868 13.5714 4.00498C13.4892 3.30235 12.9289 2.75366 12.2317 2.67573ZM4.95309 3.80146C7.31691 3.53727 9.74204 3.53727 12.1059 3.80146C12.2851 3.8215 12.426 3.96324 12.4463 4.13657C12.7263 6.53096 12.7263 8.94983 12.4463 11.3442C12.426 11.5175 12.2851 11.6593 12.1059 11.6793C9.74204 11.9435 7.31691 11.9435 4.95309 11.6793C4.77385 11.6593 4.63293 11.5175 4.61266 11.3442C4.33261 8.94983 4.33261 6.53096 4.61266 4.13657C4.63293 3.96324 4.77385 3.8215 4.95309 3.80146Z" fill="black" fillOpacity="0.8" />
                    </svg>
                  </div>
                }
              </div>
              <span style={{ color: "#FF0000", fontSize: "12px", fontWeight: "400" }}>{tokenDataError}</span>
            </div>
            {/*  */}

            {/* Caption App  */}
            <div style={{ marginBottom: "15px" }}>
              <span style={{ fontSize: "14px", fontWeight: "bold" }}>Caption App - Online order Integration Token</span><br />
              <div style={{ paddingLeft: "10px", display: "flex", alignItems: "center", justifyContent: "space-between", borderRadius: "5px", height: "36px", border: tokenDataCaptainError !== "" ? "1px solid #FF0000" : "1px solid rgba(0, 0, 0, 0.2)", marginTop: "6px" }}>
                {tokenDataCaptain !== "" ?
                  <input
                    onKeyDown={(e) => {
                      // Allow navigation keys: ArrowLeft, ArrowRight, Home, End
                      if (
                        ["ArrowLeft", "ArrowRight", "Home", "End", "Tab", "Shift", "Control"].includes(
                          e.key
                        )
                      ) {
                        return;
                      }
                      e.preventDefault(); // Prevent other keys
                    }}
                    onInput={(e) => e.preventDefault()}  // Prevent other input actions
                    onPaste={(e) => e.preventDefault()}  // Prevent pasting
                    onClick={() => {
                      console.log("");
                    }}
                    style={{ backgroundColor: "transparent", outline: "none", fontSize: "12px", cursor: "default", fontWeight: "400", height: "33px", border: "none", width: "90%" }} defaultValue={tokenDataCaptain.token} />
                  :
                  <span onClick={() => getTokenApi(2)} style={{ width: "100%", marginRight: "10px", textAlign: "end", fontSize: '12px', color: '#5C9CE8', cursor: "pointer" }}>View Token</span>
                }
                {tokenDataCaptain &&
                  <div className="copyBtn" onClick={() => copy(tokenDataCaptain.token)} style={{ width: "9%", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", borderLeft: "1px solid rgba(0, 0, 0, 0.2)", height: "36px", }}>
                    <svg width="18" height="18" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.13124 0C2.73312 0 0.789062 1.94406 0.789062 4.34217V9.709C0.789062 10.0218 1.04264 10.2754 1.35543 10.2754C1.66823 10.2754 1.9218 10.0218 1.9218 9.709V4.34217C1.9218 2.56965 3.35872 1.13274 5.13124 1.13274H10.4271C10.7399 1.13274 10.9934 0.879168 10.9934 0.566371C10.9934 0.253573 10.7399 0 10.4271 0H5.13124Z" fill="black" fillOpacity="0.8" />
                      <path fillRule="evenodd" clipRule="evenodd" d="M12.2317 2.67573C9.78424 2.40219 7.27471 2.40219 4.82727 2.67573C4.13005 2.75366 3.56977 3.30235 3.48759 4.00498C3.19732 6.4868 3.19732 8.99399 3.48759 11.4758C3.56977 12.1784 4.13005 12.7271 4.82727 12.8051C7.27471 13.0786 9.78424 13.0786 12.2317 12.8051C12.9289 12.7271 13.4892 12.1784 13.5714 11.4758C13.8616 8.99399 13.8616 6.4868 13.5714 4.00498C13.4892 3.30235 12.9289 2.75366 12.2317 2.67573ZM4.95309 3.80146C7.31691 3.53727 9.74204 3.53727 12.1059 3.80146C12.2851 3.8215 12.426 3.96324 12.4463 4.13657C12.7263 6.53096 12.7263 8.94983 12.4463 11.3442C12.426 11.5175 12.2851 11.6593 12.1059 11.6793C9.74204 11.9435 7.31691 11.9435 4.95309 11.6793C4.77385 11.6593 4.63293 11.5175 4.61266 11.3442C4.33261 8.94983 4.33261 6.53096 4.61266 4.13657C4.63293 3.96324 4.77385 3.8215 4.95309 3.80146Z" fill="black" fillOpacity="0.8" />
                    </svg>
                  </div>
                }
              </div>
              <span style={{ color: "#FF0000", fontSize: "12px", fontWeight: "400" }}>{tokenDataCaptainError}</span>
            </div>
            {/*  */}

            {/* Web Inventory */}
            <div style={{ marginBottom: "15px" }}>
              <span style={{ fontSize: "14px", fontWeight: "bold" }}>Web Inventory Menu Sync Token</span><br />
              <div style={{ paddingLeft: "10px", display: "flex", alignItems: "center", justifyContent: "space-between", borderRadius: "5px", height: "36px", border: webInventoryError !== "" ? "1px solid #FF0000" : "1px solid rgba(0, 0, 0, 0.2)", marginTop: "6px" }}>
                {webInventory !== "" ?
                  <input
                    onKeyDown={(e) => {
                      // Allow navigation keys: ArrowLeft, ArrowRight, Home, End
                      if (
                        ["ArrowLeft", "ArrowRight", "Home", "End", "Tab", "Shift", "Control"].includes(
                          e.key
                        )
                      ) {
                        return;
                      }
                      e.preventDefault(); // Prevent other keys
                    }}
                    onInput={(e) => e.preventDefault()}  // Prevent other input actions
                    onPaste={(e) => e.preventDefault()}  // Prevent pasting
                    onClick={() => {
                      console.log("");
                    }}
                    style={{ backgroundColor: "transparent", outline: "none", fontSize: "12px", cursor: "default", fontWeight: "400", height: "33px", border: "none", width: "90%" }} defaultValue={webInventory.token} />
                  :
                  <span onClick={() => getTokenApi(3)} style={{ width: "100%", marginRight: "10px", textAlign: "end", fontSize: '12px', color: '#5C9CE8', cursor: "pointer" }}>View Token</span>
                }
                {webInventory &&
                  <div className="copyBtn" onClick={() => copy(webInventory.token)} style={{ width: "9%", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", borderLeft: "1px solid rgba(0, 0, 0, 0.2)", height: "36px", }}>
                    <svg width="18" height="18" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.13124 0C2.73312 0 0.789062 1.94406 0.789062 4.34217V9.709C0.789062 10.0218 1.04264 10.2754 1.35543 10.2754C1.66823 10.2754 1.9218 10.0218 1.9218 9.709V4.34217C1.9218 2.56965 3.35872 1.13274 5.13124 1.13274H10.4271C10.7399 1.13274 10.9934 0.879168 10.9934 0.566371C10.9934 0.253573 10.7399 0 10.4271 0H5.13124Z" fill="black" fillOpacity="0.8" />
                      <path fillRule="evenodd" clipRule="evenodd" d="M12.2317 2.67573C9.78424 2.40219 7.27471 2.40219 4.82727 2.67573C4.13005 2.75366 3.56977 3.30235 3.48759 4.00498C3.19732 6.4868 3.19732 8.99399 3.48759 11.4758C3.56977 12.1784 4.13005 12.7271 4.82727 12.8051C7.27471 13.0786 9.78424 13.0786 12.2317 12.8051C12.9289 12.7271 13.4892 12.1784 13.5714 11.4758C13.8616 8.99399 13.8616 6.4868 13.5714 4.00498C13.4892 3.30235 12.9289 2.75366 12.2317 2.67573ZM4.95309 3.80146C7.31691 3.53727 9.74204 3.53727 12.1059 3.80146C12.2851 3.8215 12.426 3.96324 12.4463 4.13657C12.7263 6.53096 12.7263 8.94983 12.4463 11.3442C12.426 11.5175 12.2851 11.6593 12.1059 11.6793C9.74204 11.9435 7.31691 11.9435 4.95309 11.6793C4.77385 11.6593 4.63293 11.5175 4.61266 11.3442C4.33261 8.94983 4.33261 6.53096 4.61266 4.13657C4.63293 3.96324 4.77385 3.8215 4.95309 3.80146Z" fill="black" fillOpacity="0.8" />
                    </svg>
                  </div>
                }
              </div>
              <span style={{ color: "#FF0000", fontSize: "12px", fontWeight: "400" }}>{webInventoryError}</span>
            </div>
            {/*  */}

            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
              <small style={{ fontSize: "14px", fontWeight: "600", marginRight: "10px" }}>
                Menu Sync
              </small>
              <Radio
                small
                toggle
                // style={{ float: 'right' }}
                onChange={posSyncHandler}
                checked={Boolean(posSync && posSync)}
              />
            </div>
          </div>



          {/* <div style={{ float: 'left', fontSize: '1.8vh', fontWeight: 'bold' }}>
            TwoWaySync Services Token /PROMIS - POS Integration Token  <br />
            <span style={{ fontSize: '11px', color: '#5C9CE8' }}>
              [Note: PROMIS-POS Token use to integrate 'Table Reservation', 'E-Bill', and 'Loyalty']
            </span>
          </div>
          <div style={{ textAlign: 'right' }}>
            <Icon
              style={{ cursor: 'pointer', ...twoCopyStyle, fontSize: '17px' }}
              onClick={() => {
                setTwoCopyStyle({ color: 'green' })
                setPromisStyle({ color: '' })
                setCaptainStyle({ color: '' });
                copy(tokenData.token);
              }} name='copy outline' textAlign='right'></Icon>
          </div>
          <Form size='mini'>
            <Input
              style={{ marginLeft: '3px', width: '495px' }}
              value={tokenData && tokenData.token}
            />
          </Form>
          <div style={{ float: 'left', marginTop: '25px', fontSize: '1.8vh', fontWeight: 'bold' }}>
            Captain App - Online Order Integration Token
          </div>
          <div style={{ textAlign: 'right', paddingTop: '22px' }}>
            <Icon
              style={{ cursor: 'pointer', ...captainStyle, fontSize: '17px' }}
              onClick={() => {
                setTwoCopyStyle({ color: '' });
                setPromisStyle({ color: '' })
                setCaptainStyle({ color: 'green' });
                copy(tokenDataCaptain.token);
              }} name='copy outline' textAlign='right'></Icon>
          </div>
          <Form size='mini'>

            <Input
              style={{ marginLeft: '3px', width: '495px' }}
              value={tokenDataCaptain && tokenDataCaptain.token}
            />
          </Form> */}




          {/* commented by dasprakash on 16-01-2025 */}
          {/* <div style={{
            float: 'left',
            //  marginRight:'70px',
            paddingTop: '25px',
            fontSize: '1.8vh', fontWeight: 'bold',
          }}>
            PROMIS-POS Integration Token  <br />
            <span style={{ fontSize: '11px', color: '#5C9CE8' }}>
              [Note: PROMIS-POS Token use to integrate 'Table Reservation', 'E-Bill', and 'Loyalty']
            </span>

          </div>
          <div style={{
            textAlign: 'right',
            // float:'right',
            paddingTop: '33px',
          }}>
            <Icon
              style={{ cursor: 'pointer', ...promisStyle, fontSize: '17px' }}

              onClick={() => {
                setTwoCopyStyle({ color: '' });
                setCaptainStyle({ color: '' });
                setPromisStyle({ color: 'green' })
                copy(tokenPromis && tokenPromis.token)
              }} name='copy outline' textAlign='right'>
            </Icon>
          </div>
          <Form size='mini'>
            <Dropdown className="propertydp"
              search
              selection
              options={propertyOptions}
              onChange={promisposHandler}
              width="100px"
              placeholder="Choose Outlet"
            />
            <Input
              style={{ marginLeft: '3px', width: '340px' }}
              value={tokenPromis && tokenPromis.token}
            />
          </Form> */}
          {/*  */}

        </Modal.Content>

      </Modal>
      {/* token popup ends  */}

    </Container>
  )
}

export default ViewOrg;