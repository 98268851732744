
import { Container, Button, Grid, Table, Image, Loader, Dimmer } from 'semantic-ui-react';
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useState, useEffect, useContext } from "react";
import Result from './Result';
import ContextData from "../store/context-data";
import ModalMessage from '../Modal/ModalMessage';
const Upload = (props) => {
  const ctx = useContext(ContextData);
  const [modalOpen, setModalOpen] = useState(false)
  const [sessionProperty] = useState((sessionStorage.getItem('sessionProperty') ? JSON.parse(sessionStorage.getItem('sessionProperty')) : ''));
  const [editData] = useState(localStorage.getItem('editData') ? JSON.parse(localStorage.getItem('editData')) : '')
  const [newSelectedModules] = useState(sessionStorage.getItem('newModulesforedit') ? JSON.parse(sessionStorage.getItem('newModulesforedit')) : []);
  const [newModulesfeatures] = useState(sessionStorage.getItem('newModulesFeatures') ? JSON.parse(sessionStorage.getItem('newModulesFeatures')) : []);
  const [newSubModules] = useState(sessionStorage.getItem('newSubModules') ? JSON.parse(sessionStorage.getItem('newSubModules')) : []);

  //const [newModules] = useState([JSON.parse(sessionStorage.getItem("newModules"))]);
  //console.log('new Modules ',newModules);
  const [orgDetails] = useState(JSON.parse(sessionStorage.getItem("SessionOrgDetails")));
  const [newProperty] = useState(JSON.parse(sessionStorage.getItem("newProperty")));
  // console.log('status ', Number(newProperty.PropertyDetails.Status))
  // const[selectedModules] = useState(JSON.parse(localStorage.getItem("selectedModules")));
  const [defaultUsers] = useState(JSON.parse(localStorage.getItem("defaultUsers")));
  //  const [orgImage,setOrgImage] = useState(ctx.orgDetails.OrganizationDetails && ctx.orgDetails.OrganizationDetails.imageData);
  const [preview] = useState((orgDetails && orgDetails.OrganizationDetails.ImageUrl) || (editData && editData.propertyDetails.imageUrl) || '');
  const [previewProp] = useState((sessionProperty && sessionProperty.ImageUrl) || (editData && editData.propertyDetails.imageUrl) || '');
  const [responseData, setResponseData] = useState();
  //  const [list,setList] = useState();
  //  const [OpeningFinancialMonth, setOpeningFinancialMonth] = useState(); 
  const [loaderToggle, setLoaderToggle] = useState(false);
  const [orgImgData] = useState(localStorage.getItem("orgImgData") ? JSON.parse(localStorage.getItem("orgImgData")) : '');
  const [propImgData] = useState(localStorage.getItem("propImgData") ? JSON.parse(localStorage.getItem("propImgData")) : '');
  const [imgURLServer, setImageURLServer] = useState('');
  const [propertyImgURLServer, setPropertyImgURLServer] = useState('');
  const [waitUploadOrg, setWaitUploadOrg] = useState(true);
  const [waitUploadProp, setWaitUploadProp] = useState(true);
  const [alertMessage, setAlert] = useState('');
  //const [setCreateError] = useState('')
  //console.log('Default users ---------',defaultUsers);
  let history = useHistory();
  // const [organizationId] = useState((editData && editData.organizationDetails.organizationId) || '0')
  // const [propertyId]= useState((editData && editData.propertyDetails.propertyId) || '0')
  const modalToggleProps = () => {
    setModalOpen(false)
    setAlert('')
  }

  const goBack = () => {
    history.goBack();
  }
  const month = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
  const moduleCodeArr = newSelectedModules.map((item) => {
    if (item.ModuleCode === 'POS')
      return (
        {
          ModuleCode: item.ModuleCode,
          Features: newModulesfeatures ? newModulesfeatures.map((ft) => ft.featureId).filter(fti => fti) : [],
          //item.Features ? item.Features.map((item)=>item.featureId) :[],
          SubModules: newSubModules && newSubModules.map(item => {
            return (
              { ModuleCode: item.ModuleCode }
            )
          }).filter(item => item)

        });
    else
      return (
        {
          ModuleCode: item.ModuleCode,

        }

      )

  })
  //console.log('ModuleCode ARr',moduleCodeArr);

  useEffect(() => {

    (!waitUploadOrg && !waitUploadProp) &&
      axios.post(`${process.env.REACT_APP_BASE_API_URL}CreateOrganization`,
        {
          "OrganizationDetails": {
            "OrganizationId": (editData && editData.organizationDetails.organizationId) || Number(0),
            "OrganizationName": orgDetails.OrganizationDetails.OrganizationName,
            "ImageUrl": (imgURLServer && imgURLServer) || (editData && editData.organizationDetails.imageUrl),
            "Address": {
              "AddressLine1": orgDetails.OrganizationDetails.Address.AddressLine1,
              "AddressLine2": orgDetails.OrganizationDetails.Address.AddressLine2,
              "AddressLine3": orgDetails.OrganizationDetails.Address.AddressLine3,
              "AreaCode": Number(orgDetails.OrganizationDetails.Address.AreaCode),
              "AreaName": orgDetails.OrganizationDetails.Address.AreaName,
              "CityCode": Number(orgDetails.OrganizationDetails.Address.CityCode),
              "CityName": orgDetails.OrganizationDetails.Address.CityName,
              "StateCode": Number(orgDetails.OrganizationDetails.Address.StateCode),
              "CountryCode": Number(orgDetails.OrganizationDetails.Address.CountryCode),
              "ZipCode": orgDetails.OrganizationDetails.Address.ZipCode,
              "Landmark": orgDetails.OrganizationDetails.Address.Landmark,
            },
            "ContactDetails": {
              "MobileCountryCode": orgDetails.OrganizationDetails.ContactDetails.MobileCountryCode,
              "MobileNo": orgDetails.OrganizationDetails.ContactDetails.MobileNo,
              "TelephoneCountryCode": orgDetails.OrganizationDetails.ContactDetails.TelephoneCountryCode,
              "TelephoneAreaCode": orgDetails.OrganizationDetails.ContactDetails.TelephoneAreaCode,
              "TelephoneNo": orgDetails.OrganizationDetails.ContactDetails.TelephoneNo.length,
              "EmailId": orgDetails.OrganizationDetails.ContactDetails.EmailId,
              "Website": orgDetails.OrganizationDetails.ContactDetails.Website,
            },

            "Status": Number(orgDetails.OrganizationDetails.Status),
          },
          "PropertyDetails": {
            "PropertyId": (editData && editData.propertyDetails.propertyId) || '',
            "PropertyName": newProperty.PropertyDetails.ObjFormProperty.PropertyName,
            // added by dasprakash on 26-12-2024
            "DisplayName": newProperty.PropertyDetails.ObjFormProperty.DisplayName,
            // 
            "ShortName": newProperty.PropertyDetails.ObjFormProperty.ShortName,
            "ImageUrl": (propertyImgURLServer && propertyImgURLServer) || (editData && editData.propertyDetails.imageUrl)
              || (editData && editData.organizationDetails.imageUrl),
            "Address": {
              "AddressLine1": newProperty.PropertyDetails.formPropAdrsData.Address.AddressLine1,
              "AddressLine2": newProperty.PropertyDetails.formPropAdrsData.Address.AddressLine2,
              "AddressLine3": newProperty.PropertyDetails.formPropAdrsData.Address.AddressLine3,
              "AreaCode": Number(orgDetails.OrganizationDetails.Address.AreaCode),
              "AreaName": newProperty.PropertyDetails.formPropAdrsData.Address.AreaName,
              "CityCode": Number(orgDetails.OrganizationDetails.Address.CityCode),
              "CityName": newProperty.PropertyDetails.formPropAdrsData.Address.CityName,
              "StateCode": Number(orgDetails.OrganizationDetails.Address.StateCode),
              "CountryCode": Number(orgDetails.OrganizationDetails.Address.CountryCode),
              "ZipCode": newProperty.PropertyDetails.formPropAdrsData.Address.ZipCode,
              "Landmark": newProperty.PropertyDetails.formPropAdrsData.Address.Landmark,
              "Latitude": Number(newProperty.PropertyDetails.formPropAdrsData.Address.Latitude),
              "Longitude": Number(newProperty.PropertyDetails.formPropAdrsData.Address.Longitude),
            },
            "ContactDetails": {
              "MobileCountryCode": newProperty.PropertyDetails.formPropAdrsData.ContactDetails.MobileCountryCode,
              "MobileNo": newProperty.PropertyDetails.formPropAdrsData.ContactDetails.MobileNo,
              "TelephoneCountryCode": newProperty.PropertyDetails.formPropAdrsData.ContactDetails.TelephoneCountryCode,
              "TelephoneAreaCode": newProperty.PropertyDetails.formPropAdrsData.ContactDetails.TelephoneAreaCode,
              "TelephoneNo": newProperty.PropertyDetails.formPropAdrsData.ContactDetails.TelephoneNo,
              "EmailId": newProperty.PropertyDetails.formPropAdrsData.ContactDetails.EmailId,
              "Website": newProperty.PropertyDetails.formPropAdrsData.ContactDetails.Website
            },
            "Status": Number(newProperty.PropertyDetails.Status),
            "RegistrationDetails": newProperty.PropertyDetails.formPropRegData,
            "YearEndSettings": {
              "OpeningFinancialMonth": Number(newProperty.PropertyDetails.YearEndSettings.OpeningFinancialMonth),
              "StartDayOfTheMonth": Number(newProperty.PropertyDetails.YearEndSettings.StartDayOfTheMonth),
            },
            "SelectedModules": moduleCodeArr,
          },
          "DefaultUsers": defaultUsers,
          "SystemDetails": {
            "ApplicationName": "LUCID License Management",
            "ApplicationVersion": "1.0.0.0",
            "BrowserName": "Chrome",
            "BrowserVersion": "83",
            "DeviceId": "",
            "DeviceType": "Desktop",
            "IP": "2409:4072:198:c9b1:95a:80d4:a728:36e",
            "Mac": "0210D6A869E8",
            "OPS": "5.0 (Windows NT 10.0; Win64; x64) AppleWebKit537.36 (KHTML, like Gecko) Chrome/83.0.4103.97 Safari/537.36",
            "Source": "React App",
            "SystemName": "EC2AMAZ-EVPTFRH",
            "SystemTimeZoneId": 1
          }
        }
        , {
          headers: { Authorization: `Bearer ${ctx.APIToken}` },
          "Content-Type": "application/json"
        }

      ).then((response) => {
        //console.log(response.data);
        const resData = response.data;
        if (resData.errorCode === 1) {
          setAlert(resData.message)
          setModalOpen(true)
          // window.alert(resData.message)
          // setCreateError(resData.message)
          //  setResponseData(resData.message);
        } else {
          //console.log('uploaded the data');
          // console.log(response.data);
          setResponseData(response.data);
          //  <Redirect to='/Response' /> 
          localStorage.removeItem("orgImgData");
          localStorage.removeItem("propImgData");
        }
        setLoaderToggle(false);
      }).catch((error) => {
        console.log(error);
        setAlert(error);
        setResponseData(error.data);
        setLoaderToggle(false);
      })
    //create ends here 
    // console.log(waitUploadOrg,waitUploadProp)   
  }, [waitUploadOrg, waitUploadProp])




  const create = () => {
    window.scrollTo(0, 0);
    setLoaderToggle(true);
    orgImgData ? setWaitUploadOrg(true) : setWaitUploadOrg(false);

    orgImgData &&
      axios.post(`${process.env.REACT_APP_LUCID_API_URL}UploadImage`, {
        "FileName": orgImgData.fileName,
        "FileExtension": orgImgData.FileExtension,
        "FileData": null,
        "ImageString": orgImgData.ImageString
      }, {
        headers: { Authorization: `Bearer ${ctx.APIToken}` },
        "Content-Type": "application/json"
      }
      ).then((response) => {
        //console.log('uploaded the image');
        // console.log(response.data)
        const imageUrlServer = response.data;
        setImageURLServer(imageUrlServer.response.imageUrl);
        setWaitUploadOrg(false);
      }).catch((error) => {
        console.log(error);
        setAlert(error)
      })
    propImgData ? setWaitUploadProp(true) : setWaitUploadProp(false);
    propImgData &&
      axios.post(`${process.env.REACT_APP_LUCID_API_URL}UploadImage`, {
        "FileName": propImgData.fileName,
        "FileExtension": propImgData.FileExtension,
        "FileData": null,
        "ImageString": propImgData.ImageString
      }, {
        headers: { Authorization: `Bearer ${ctx.APIToken}` },
        "Content-Type": "application/json"
      }
      ).then((response) => {
        //console.log('uploaded the image');
        // console.log(response.data)
        const imageUrlServer = response.data;
        setPropertyImgURLServer(imageUrlServer.response.imageUrl);
        setWaitUploadProp(false);
      }).catch((error) => {
        console.log(error);
        setAlert(error)
      })

    //   const OrgMiniData =orgDetails.OrganizationDetails;
    //  const uploadData = [  {
    //    "OrganizationDetails":{
    //      "OrganizationId":(editData && editData.organizationDetails.organizationId) || Number(0), 
    //        "OrganizationName":orgDetails.OrganizationDetails.OrganizationName,
    //        "ImageUrl": (imgURLServer && imgURLServer) || (editData && editData.organizationDetails.imageUrl) ,
    //        "Address":{
    //            "AddressLine1": orgDetails.OrganizationDetails.Address.AddressLine1,
    //            "AddressLine2": orgDetails.OrganizationDetails.Address.AddressLine2,
    //            "AddressLine3": orgDetails.OrganizationDetails.Address.AddressLine3,
    //            "AreaCode": Number(orgDetails.OrganizationDetails.Address.AreaCode),   
    //            "AreaName":orgDetails.OrganizationDetails.Address.AreaName,
    //            "CityCode":Number(orgDetails.OrganizationDetails.Address.CityCode),
    //            "CityName":orgDetails.OrganizationDetails.Address.CityName,
    //            "StateCode":Number(orgDetails.OrganizationDetails.Address.StateCode),
    //            "CountryCode":Number(orgDetails.OrganizationDetails.Address.CountryCode),
    //            "ZipCode": orgDetails.OrganizationDetails.Address.ZipCode,
    //            "Landmark": orgDetails.OrganizationDetails.Address.Landmark,
    //        },
    //        "ContactDetails":{     
    //            "MobileCountryCode":orgDetails.OrganizationDetails.ContactDetails.MobileCountryCode,
    //            "MobileNo":orgDetails.OrganizationDetails.ContactDetails.MobileNo,
    //            "TelephoneCountryCode":orgDetails.OrganizationDetails.ContactDetails.TelephoneCountryCode,
    //            "TelephoneAreaCode":orgDetails.OrganizationDetails.ContactDetails.TelephoneAreaCode,
    //            "TelephoneNo":orgDetails.OrganizationDetails.ContactDetails.TelephoneNo.length,
    //            "EmailId": orgDetails.OrganizationDetails.ContactDetails.EmailId,
    //            "Website": orgDetails.OrganizationDetails.ContactDetails.Website, 
    //        },
    //       
    //        "Status":Number(orgDetails.OrganizationDetails.Status),
    //    },
    //    "PropertyDetails":{
    //      "PropertyId":(editData && editData.propertyDetails.propertyId) || '',
    //        "PropertyName": newProperty.PropertyDetails.ObjFormProperty.PropertyName,
    //        "ShortName":newProperty.PropertyDetails.ObjFormProperty.ShortName,
    //        "ImageUrl":propertyImgURLServer && propertyImgURLServer,
    //        "Address":{    
    //            "AddressLine1":newProperty.PropertyDetails.formPropAdrsData.Address.AddressLine1, 
    //            "AddressLine2":newProperty.PropertyDetails.formPropAdrsData.Address.AddressLine2,
    //            "AddressLine3":newProperty.PropertyDetails.formPropAdrsData.Address.AddressLine3,
    //            "AreaCode":Number(orgDetails.OrganizationDetails.Address.AreaCode),  
    //            "AreaName":newProperty.PropertyDetails.formPropAdrsData.Address.AreaName, 
    //            "CityCode": Number(orgDetails.OrganizationDetails.Address.CityCode),
    //            "CityName":newProperty.PropertyDetails.formPropAdrsData.Address.CityName,
    //            "StateCode":Number(orgDetails.OrganizationDetails.Address.StateCode),
    //            "CountryCode":Number(orgDetails.OrganizationDetails.Address.CountryCode),
    //            "ZipCode":newProperty.PropertyDetails.formPropAdrsData.Address.ZipCode,
    //            "Landmark":newProperty.PropertyDetails.formPropAdrsData.Address.Landmark,
    //            "Latitude":Number(newProperty.PropertyDetails.formPropAdrsData.Address.Latitude),
    //            "Longitude": Number(newProperty.PropertyDetails.formPropAdrsData.Address.Longitude),
    //        },
    //        "ContactDetails":{
    //            "MobileCountryCode":newProperty.PropertyDetails.formPropAdrsData.ContactDetails.MobileCountryCode,
    //            "MobileNo":newProperty.PropertyDetails.formPropAdrsData.ContactDetails.MobileNo,
    //            "TelephoneCountryCode":newProperty.PropertyDetails.formPropAdrsData.ContactDetails.TelephoneCountryCode,
    //            "TelephoneAreaCode":newProperty.PropertyDetails.formPropAdrsData.ContactDetails.TelephoneAreaCode,
    //            "TelephoneNo":newProperty.PropertyDetails.formPropAdrsData.ContactDetails.TelephoneNo,
    //            "EmailId":newProperty.PropertyDetails.formPropAdrsData.ContactDetails.EmailId,
    //            "Website":newProperty.PropertyDetails.formPropAdrsData.ContactDetails.Website
    //        },
    //        "Status": Number(newProperty.PropertyDetails.Status),
    //        "RegistrationDetails":newProperty.PropertyDetails.formPropRegData,
    //        "YearEndSettings":{
    //            "OpeningFinancialMonth":Number(newProperty.PropertyDetails.YearEndSettings.OpeningFinancialMonth),   
    //            "StartDayOfTheMonth": Number(newProperty.PropertyDetails.YearEndSettings.StartDayOfTheMonth),
    //        },
    //        "SelectedModules": moduleCodeArr,
    //    },
    //    "DefaultUsers":defaultUsers,
    //}]; 
    // console.log(' uploading data ---',uploadData); 
    //console.log('Data upload inti Img Url');
    //  console.log(ctx.APIToken);

  }
  if (orgDetails && !responseData) {
    return (
      <Container>
        <Grid>     <Grid.Row columns={1} style={{ marginTop: '100px' }}>

          <Grid.Column width={16} textAlign='center'>
            <h2> License Preview</h2>
            <p style={{ color: 'red', fontWeight: 'bold' }}>{alertMessage}</p>
          </Grid.Column>
        </Grid.Row>

          <h3> Organisation Details</h3>
          <Grid.Row columns={2}>
            <Grid.Column width={8} >
              {loaderToggle &&
                <Dimmer active >
                  <Loader>Loading </Loader>
                </Dimmer>
              }
              <Table color='blue' columns={2} celled inverted selectable >
                <Table.Header>
                  <Table.Row textAlign='center'>
                    <Table.HeaderCell>Fields</Table.HeaderCell>
                    <Table.HeaderCell>Values</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Organization Name</Table.Cell>
                    <Table.Cell>{orgDetails.OrganizationDetails.OrganizationName}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row><Table.Cell>Area Name</Table.Cell><Table.Cell>{orgDetails.OrganizationDetails.Address.AreaName || null}</Table.Cell></Table.Row>
                  <Table.Row><Table.Cell>City Name</Table.Cell><Table.Cell>{orgDetails.OrganizationDetails.Address.CityName || null}</Table.Cell></Table.Row>
                  <Table.Row><Table.Cell>ZipCode</Table.Cell><Table.Cell>{orgDetails.OrganizationDetails.Address.ZipCode || null}</Table.Cell></Table.Row>
                  <Table.Row><Table.Cell>Landmark</Table.Cell><Table.Cell>{orgDetails.OrganizationDetails.Address.Landmark || null}</Table.Cell></Table.Row>
                  <Table.Row style={{ backgroundColor: 'darkblue' }}><Table.Cell colSpan={2}><h4>Contact Details</h4></Table.Cell></Table.Row>
                  <Table.Row><Table.Cell>MobileNo</Table.Cell><Table.Cell>{orgDetails.OrganizationDetails.ContactDetails.MobileCountryCode} {'  '} {orgDetails.OrganizationDetails.ContactDetails.MobileNo || null}</Table.Cell></Table.Row>
                  <Table.Row> <Table.Cell>TelephoneNo</Table.Cell><Table.Cell>{orgDetails.OrganizationDetails.ContactDetails.TelephoneCountryCode} {orgDetails.OrganizationDetails.ContactDetails.TelephoneAreaCode}  {orgDetails.OrganizationDetails.ContactDetails.TelephoneNo || null}</Table.Cell></Table.Row>
                  <Table.Row><Table.Cell>EmailId</Table.Cell><Table.Cell>{orgDetails.OrganizationDetails.ContactDetails.EmailId || null}</Table.Cell></Table.Row>
                  <Table.Row><Table.Cell>Website</Table.Cell><Table.Cell>{orgDetails.OrganizationDetails.ContactDetails.Website || null}</Table.Cell></Table.Row>
                  <Table.Row><Table.Cell>Status</Table.Cell><Table.Cell>{orgDetails.OrganizationDetails.Status === 1 ? 'Active' : 'Passive'}</Table.Cell></Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
            <Grid.Column width={5}>
              {(preview && <Image size='small' rounded src={preview} alt='organization picprop' />) ||
                (editData && <Image size='small' rounded src={editData.organizationDetails.imageUrl} alt='organization picprop' />)
                || <i className="fa fa-picture-o" aria-hidden="true" style={{ fontSize: '150px' }}></i>
              }
            </Grid.Column>
          </Grid.Row>
          <h3>Property Details</h3>
          <Grid.Row columns={2}>

            <Grid.Column width={8}>

              <Table color='blue' columns={2} celled inverted selectable >
                <Table.Header>
                  <Table.Row textAlign='center'>
                    <Table.HeaderCell>Fields</Table.HeaderCell>
                    <Table.HeaderCell>Values</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Organization Name</Table.Cell>
                    <Table.Cell>{orgDetails.OrganizationDetails.OrganizationName}</Table.Cell>
                  </Table.Row>
                  <Table.Row><Table.Cell>PropertyName</Table.Cell><Table.Cell>{newProperty.PropertyDetails.ObjFormProperty.PropertyName || null}</Table.Cell></Table.Row>
                  {/* // added by dasprakash on 26-12-2024 */}
                  <Table.Row>
                    <Table.Cell>DisplayName</Table.Cell>
                    <Table.Cell>{newProperty.PropertyDetails.ObjFormProperty.DisplayName || null}</Table.Cell>
                  </Table.Row>
                  {/*  */}
                  <Table.Row><Table.Cell>ShortName</Table.Cell><Table.Cell>{newProperty.PropertyDetails.ObjFormProperty.ShortName || null}</Table.Cell></Table.Row>
                  <Table.Row style={{ backgroundColor: 'darkblue' }}><Table.Cell colSpan={2}><h4>Address</h4></Table.Cell></Table.Row>
                  <Table.Row><Table.Cell>AddressLine1</Table.Cell><Table.Cell>{newProperty.PropertyDetails.formPropAdrsData.Address.AddressLine1 || null}</Table.Cell></Table.Row>
                  <Table.Row><Table.Cell>AddressLine2</Table.Cell><Table.Cell>{newProperty.PropertyDetails.formPropAdrsData.Address.AddressLine2 || null}</Table.Cell></Table.Row>
                  <Table.Row><Table.Cell>AddressLine3</Table.Cell><Table.Cell>{newProperty.PropertyDetails.formPropAdrsData.Address.AddressLine3 || null}</Table.Cell></Table.Row>
                  <Table.Row><Table.Cell>Area Name</Table.Cell><Table.Cell>{newProperty.PropertyDetails.formPropAdrsData.Address.AreaName || null}</Table.Cell></Table.Row>
                  <Table.Row><Table.Cell>City Name</Table.Cell><Table.Cell>{newProperty.PropertyDetails.formPropAdrsData.Address.CityName || null}</Table.Cell></Table.Row>
                  <Table.Row><Table.Cell>ZipCode</Table.Cell><Table.Cell>{newProperty.PropertyDetails.formPropAdrsData.Address.ZipCode || null}</Table.Cell></Table.Row>
                  <Table.Row><Table.Cell>Landmark</Table.Cell><Table.Cell>{newProperty.PropertyDetails.formPropAdrsData.Address.Landmark || null}</Table.Cell></Table.Row>
                  <Table.Row><Table.Cell>Latitude</Table.Cell><Table.Cell>{newProperty.PropertyDetails.formPropAdrsData.Address.Latitude || null}</Table.Cell></Table.Row>
                  <Table.Row><Table.Cell>Longitude</Table.Cell><Table.Cell>{newProperty.PropertyDetails.formPropAdrsData.Address.Longitude || null}</Table.Cell></Table.Row>
                  <Table.Row style={{ backgroundColor: 'darkblue' }}><Table.Cell colSpan={2}><h4>ContactDetails</h4></Table.Cell></Table.Row>
                  <Table.Row><Table.Cell>Mobile No</Table.Cell><Table.Cell>{newProperty.PropertyDetails.formPropAdrsData.ContactDetails.MobileCountryCode || null} {newProperty.PropertyDetails.formPropAdrsData.ContactDetails.MobileNo || null}</Table.Cell></Table.Row>
                  <Table.Row><Table.Cell>TelephoneNo</Table.Cell><Table.Cell>{newProperty.PropertyDetails.formPropAdrsData.ContactDetails.TelephoneCountryCode || null} {newProperty.PropertyDetails.formPropAdrsData.ContactDetails.TelephoneAreaCode}  {newProperty.PropertyDetails.formPropAdrsData.ContactDetails.TelephoneNo || null}</Table.Cell></Table.Row>
                  <Table.Row><Table.Cell>EmailId</Table.Cell><Table.Cell>{newProperty.PropertyDetails.formPropAdrsData.ContactDetails.EmailId || null}</Table.Cell></Table.Row>
                  <Table.Row><Table.Cell>Website</Table.Cell><Table.Cell>{newProperty.PropertyDetails.formPropAdrsData.ContactDetails.Website || null}</Table.Cell></Table.Row>
                  <Table.Row ><Table.Cell>Status</Table.Cell><Table.Cell>{newProperty.PropertyDetails.Status === 1 ? 'Active' : 'Passive'}</Table.Cell></Table.Row>
                  <Table.Row style={{ backgroundColor: 'darkblue' }}> <Table.Cell colSpan={2}><h4>RegistrationDetails</h4></Table.Cell></Table.Row>
                  {newProperty.PropertyDetails.formPropRegData.map((item, idr) => {
                    return (
                      <Table.Row key={idr}>
                        <Table.Cell>{item.registrationTypeName}
                        </Table.Cell>
                        <Table.Cell>{item.RegistrationNumber}
                        </Table.Cell>
                      </Table.Row>
                    )
                  })}
                  <Table.Row style={{ backgroundColor: 'darkblue' }}><Table.Cell colSpan={2}><h4>YearEndSettings</h4></Table.Cell></Table.Row>
                  <Table.Row><Table.Cell>OpeningFinancialMonth</Table.Cell><Table.Cell>{month[newProperty.PropertyDetails.YearEndSettings.OpeningFinancialMonth - 1]}</Table.Cell></Table.Row>
                  <Table.Row><Table.Cell>Start Day Of The Month</Table.Cell><Table.Cell>{newProperty.PropertyDetails.YearEndSettings.StartDayOfTheMonth}</Table.Cell></Table.Row>
                  <Table.Row style={{ backgroundColor: 'darkblue' }}><Table.Cell colSpan={2}><h4>Selected Modules</h4></Table.Cell></Table.Row>
                  <Table.Row><Table.Cell colSpan={2}>
                    {
                      newSelectedModules && newSelectedModules.map((module, idmnm) => {
                        return (
                          <ul key={idmnm}>
                            <li key={idmnm + module.ModuleName}>{module.ModuleName}</li>
                            {
                              newSubModules &&
                              newSubModules.map((subModule, subid) => {
                                if (module.ModuleCode === 'POS') {
                                  //console.log(subModule.ModuleName)
                                  return (
                                    <li key={subid}>
                                      <ul>
                                        <li>{subModule.ModuleName}</li>
                                      </ul>
                                    </li>
                                  )

                                } else return null
                              })
                            }
                            {
                              newModulesfeatures &&
                              module.ModuleCode === 'POS' &&
                              newModulesfeatures.map((feature, fid) => {
                                return (
                                  <li key={fid}>
                                    <ul>
                                      <li>{feature.featureName}</li>
                                    </ul>
                                  </li>
                                )
                              })
                            }
                          </ul>
                        )
                      })}
                  </Table.Cell></Table.Row>
                  <Table.Row style={{ backgroundColor: 'darkblue' }}><Table.Cell colSpan={2}><h4>User Details</h4></Table.Cell></Table.Row>
                  <Table.Row><Table.Cell colSpan={2}><h4>DefaultUsers</h4></Table.Cell></Table.Row>
                  <Table.Row><Table.Cell><h4>User ID</h4></Table.Cell><Table.Cell>Password</Table.Cell></Table.Row>
                  {defaultUsers && defaultUsers.map((item, did) => {
                    return (
                      <Table.Row key={did}><Table.Cell>{item.UserId}</Table.Cell><Table.Cell>{item.Password}</Table.Cell></Table.Row>
                    )
                  })}
                </Table.Body>
              </Table>
              <Grid>
                <Grid.Row columns={2}>
                  {loaderToggle &&
                    <Dimmer active>
                      <Loader>Loading</Loader>
                    </Dimmer>}
                  <Grid.Column width={4} floated='left'>
                    <Button
                      color='blue'
                      onClick={goBack}
                    > Go Back</Button>
                  </Grid.Column>
                  <Grid.Column width={3} floated='right'>
                    {(editData && <Button color='blue' onClick={create}>Update</Button>) ||
                      <Button color='blue' onClick={create}>Create</Button>
                    }
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column width={8}>   {
              //right colums starts here
            }
              <Grid.Row columns={1} >
                <Grid.Column width={3} style={{ height: '150px', marginBottom: '1000px' }}>
                  {(previewProp && <Image size='small' rounded src={previewProp} alt='Property Image' />) ||
                    (preview && <Image size='small' rounded src={preview} alt='organization picprop' />) ||
                    (editData && <Image size='small' rounded src={editData.propertyDetails.imageUrl} alt='Property Image' />) ||
                    <i className="fa fa-picture-o" aria-hidden="true" style={{ fontSize: '150px' }}></i>
                  }
                </Grid.Column>
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row >
            <Grid.Column width={4} floated='right'>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ModalMessage open={modalOpen} modalToggleProps={modalToggleProps} message={alertMessage} />
      </Container>
    )
  } else if (responseData) {
    return (
      <Result data={responseData} />
    )
  }
  else {
    return (
      <>
        <h3> Loading.....</h3>
      </>
    )
  }

}
export default Upload;